/*-----------------------------------------------------------------------------------

    Template Name: Sample - Corporate Business Bootstrap4 HTML5 Template
    Template URI: site.com
    Description: Sample - Corporate Business Bootstrap4 HTML5 Template
    Author: MD THAHERIL ISLAM
    Author URI: site.com
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/
/*===========================
    1. COMMON css 
===========================*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

body {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #505056;
    overflow-x: hidden;
}

/* ==========================================vue */
* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

img {
    max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover {
    text-decoration: none;
}

i,
span,
a {
    display: inline-block;
    transition: all linear 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    color: #1c435d;
    margin: 0px;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

ul,
ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #505056;
    margin: 0px;
}

.bg_cover {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.slick-slide {
    outline: 0;
}

/*===== All Button Style =====*/
.main-btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #2b70fa;
    padding: 0 30px;
    font-size: 15px;
    line-height: 45px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    z-index: 5;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    background-color: #2b70fa;
}

.main-btn:hover {
    background-color: #fff;
    color: #2b70fa;
    border-color: #2b70fa;
}

.main-btn.main-btn-2 {
    background-color: #fff;
    color: #2b70fa;
    border-color: #2b70fa;
}

.main-btn.main-btn-2:hover {
    background-color: #2b70fa;
    border-color: #2b70fa;
    color: #fff;
}

.appie-section-title {
    padding-bottom: 25px;
}

.appie-section-title .appie-title {
    font-size: 44px;
    line-height: 30px;
    font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-section-title .appie-title {
        font-size: 36px;
        line-height: 46px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-section-title .appie-title {
        font-size: 36px;
        line-height: 46px;
    }
}

@media (max-width: 767px) {
    .appie-section-title .appie-title {
        font-size: 26px;
        line-height: 36px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-section-title .appie-title {
        font-size: 36px;
        line-height: 46px;
    }
}

.appie-section-title p {
    font-size: 18px;
    line-height: 28px;
    margin-top: 11px;
    font-weight: 300;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-section-title p {
        font-size: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-section-title p {
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    .appie-section-title p {
        font-size: 15px;
    }
}

.appie-section-title .main-btn {
    border-radius: 30px;
    border: 2px solid #e7eaef;
    background: #fff;
    color: #0e1133;
}

.appie-section-title .main-btn:hover {
    background: #ff3e66;
    color: #fff;
    border-color: #ff3e66;
}

.appie-section-title.appie-section-title-2 .appie-title {
    color: #fff;
}

.appie-section-title.appie-section-title-2 p {
    color: #fff;
}

.back-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 999;
    display: none;
}

.back-to-top a {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background: #1c435d;
    border-radius: 6px;
    color: #fff;
}

.back-to-top.back-to-top-2 a {
    background: #ff3e66;
}

.back-to-top.back-to-top-3 a {
    background: #ff6b58;
}

.back-to-top.back-to-top-6 a {
    background: #9b2cfa;
}

.back-to-top.back-to-top-5 a {
    background: #801f82;
}

.back-to-top.back-to-top-8 a {
    background: #db0f30;
}

.off_canvars_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9998;
    opacity: 0;
    visibility: hidden;
    cursor: crosshair;
    background: #232323;
    top: 0;
    transition: all linear 0.3s;
}

.off_canvars_overlay.active {
    opacity: 0.5;
    visibility: visible;
}

.offcanvas_menu {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .offcanvas_menu {
        display: block;
    }
}

@media (max-width: 767px) {
    .offcanvas_menu {
        display: block;
    }
}

.offcanvas_menu_wrapper {
    width: 290px;
    position: fixed;
    background: #fff;
    z-index: 9999;
    top: 0;
    height: 100%;
    transition: 0.5s;
    left: 0;
    margin-left: -300px;
    padding: 50px 15px 30px;
    overflow-y: visible;
}

.offcanvas_menu_wrapper.active {
    margin-left: 0;
}

.offcanvas_menu_wrapper .slinky-theme-default {
    background: inherit;
    min-height: 300px;
    overflow-y: auto;
}

.offcanvas_menu_wrapper .header-btn {
    margin-bottom: 30px;
}

.offcanvas_menu_wrapper .header-btn a {
    color: #222;
}

.offcanvas_menu_wrapper .header-btn a:hover {
    color: #222;
}

.offcanvas_main_menu li {
    position: relative;
}

.offcanvas_main_menu li:last-child {
    margin: 0;
}

.offcanvas_main_menu li span.menu-expand {
    position: absolute;
    right: 0;
}

.offcanvas_main_menu li a {
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ededed;
    color: #222;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.offcanvas_main_menu li a:hover {
    color: #2b70fa;
}

.offcanvas_main_menu li ul.sub-menu {
    padding-left: 20px;
}

.offcanvas_footer {
    padding-bottom: 50px;
    text-align: center;
}

.offcanvas_footer span a {
    font-size: 14px;
    color: #222;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.offcanvas_footer span a:hover {
    color: #2b70fa;
}

.offcanvas_menu_wrapper.active .canvas_close {
    opacity: 1;
    visibility: visible;
}

.canvas_close {
    position: absolute;
    top: 10px;
    right: -50px;
    opacity: 0;
    visibility: hidden;
    transition: all linear 0.3s;
}

.canvas_close a {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    line-height: 40px;
    border: 1px solid #2b70fa;
    border-radius: 10px;
    background: #2b70fa;
    color: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.canvas_close a:hover {
    background: #2b70fa;
    border-color: #2b70fa;
    color: #fff;
}

.canvas_open a {
    font-size: 26px;
    width: 50px;
    height: 42px;
    display: block;
    line-height: 39px;
    text-align: center;
    border: 1px solid #232323;
    color: #222;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.canvas_open a:hover {
    color: #2b70fa;
    border-color: #2b70fa;
}

.offcanvas-social {
    margin-bottom: 40px;
    margin-top: 40px;
}

.offcanvas-social ul li {
    display: inline-block;
}

.offcanvas-social ul li a {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    color: #2b70fa;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    margin: 0 5px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.offcanvas-social ul li a:hover {
    background: #2b70fa;
    border-color: #2b70fa;
    color: #fff;
}

.toggle-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 20px;
    color: #0e1133;
}

.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../images/icon/preloader.svg);
}

.preloader-close {
    position: fixed;
    z-index: 999999;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    right: 40px;
    bottom: 40px;
    font-weight: 600;
    line-height: 60px;
    background: #2b70fa;
    padding: 0 15px;
    border-radius: 5px;
}

.loader-wrap {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
}

.loader-wrap .layer-one {
    position: absolute;
    left: 0%;
    top: 0;
    width: 33.3333%;
    height: 100%;
    overflow: hidden;
}

.loader-wrap .layer-two {
    position: absolute;
    left: 33.3333%;
    top: 0;
    width: 33.3333%;
    height: 100%;
    overflow: hidden;
}

.loader-wrap .layer-three {
    position: absolute;
    left: 66.6666%;
    top: 0;
    width: 33.3333%;
    height: 100%;
    overflow: hidden;
}

.loader-wrap .layer .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #f4f9ff;
}

/*===========================
    SEARCH css 
===========================*/
.search-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 9999999;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.6s ease-out 0s;
    -moz-transition: all 0.6s ease-out 0s;
    -ms-transition: all 0.6s ease-out 0s;
    -o-transition: all 0.6s ease-out 0s;
    transition: all 0.6s ease-out 0s;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
}

.search-box .search-header .search-title {
    font-size: 24px;
    font-weight: 500;
    color: #fff;
}

.search-box .search-header .search-close button {
    background: none;
    border: 0;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    padding-right: 35px;
    position: relative;
}

.search-box .search-header .search-close button span {
    width: 21px;
    height: 2px;
    background-color: #fff;
    display: block;
    position: absolute;
    right: 0;
}

.search-box .search-header .search-close button span:nth-child(1) {
    top: 13px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.search-box .search-header .search-close button span:nth-child(2) {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    top: 13px;
}

.search-box .search-body {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.search-box .search-body .search-form {
    position: relative;
}

.search-box .search-body .search-form input {
    width: 100%;
    border: 0;
    height: 80px;
    padding-left: 30px;
    border: 2px solid #fff;
    border-radius: 50px;
    font-size: 20px;
    color: #707070;
}

.search-box .search-body .search-form input::placeholder {
    color: #707070;
    opacity: 1;
}

.search-box .search-body .search-form button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    height: 60px;
    font-size: 36px;
    color: #707070;
    background: none;
    border: 0;
}

.search-box .search-footer {
    padding-bottom: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.search-box .search-footer .search-footer-content h4 {
    color: #707070;
    font-size: 24px;
}

.search-box .search-footer .search-footer-content p {
    color: #222;
    font-size: 16px;
}

.search-box.open {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

/*===========================
    SHOPPING CART css 
===========================*/
.amm-shopping-cart-wrapper .overlay::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #222;
    opacity: 0;
    z-index: 99999;
    visibility: visible;
    -webkit-transition: all 0.2s ease-out 0s;
    -moz-transition: all 0.2s ease-out 0s;
    -ms-transition: all 0.2s ease-out 0s;
    -o-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
    visibility: hidden;
}

.amm-shopping-cart-wrapper .overlay.open::before {
    opacity: 0.5;
    visibility: visible;
}

.amm-shopping-cart-canvas {
    position: fixed;
    width: 400px;
    height: 100%;
    background-color: #fff;
    z-index: 99999999;
    top: 0;
    right: -400px;
    padding: 40px 40px;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

@media (max-width: 767px) {
    .amm-shopping-cart-canvas {
        width: 320px;
    }
}

.amm-shopping-cart-canvas.open {
    right: 0;
}

.amm-shopping-cart-canvas .amm-shopping_cart {
    position: relative;
    height: 100%;
    width: 100%;
}

.amm-shopping-cart-canvas.amm-shopping-cart-leftbar {
    right: auto;
    left: -400px;
}

.amm-shopping-cart-canvas.amm-shopping-cart-leftbar.open {
    left: 0;
}

.amm-shopping_cart-list-items {
    overflow-y: auto;
    width: 100%;
    height: 50%;
}

.amm-shopping_cart-list-items ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.amm-shopping_cart-list-items ul li {
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5;
}

.amm-shopping_cart-list-items ul li:last-child {
    border-bottom: 0;
}

.amm-single-shopping-cart {
    display: flex;
}

.amm-single-shopping-cart .cart-content {
    position: relative;
    padding-right: 30px;
}

.amm-single-shopping-cart .cart-content h6 a {
    font-size: 16px;
    font-weight: 500;
    color: #222;
}

.amm-single-shopping-cart .cart-content .quality {
    font-size: 14px;
    color: #505056;
    display: block;
}

.amm-single-shopping-cart .cart-content .price {
    font-size: 14px;
    color: #2b70fa;
}

.amm-single-shopping-cart .cart-content .price-discount {
    font-size: 14px;
    color: #2b70fa;
}

.amm-single-shopping-cart .cart-content .price-close {
    font-size: 12px;
    color: #505056;
    position: relative;
}

.amm-single-shopping-cart .cart-content .price-close::before {
    position: absolute;
    content: "";
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #505056;
}

.amm-single-shopping-cart .cart-content .remove {
    font-size: 14px;
    color: #ccc;
    position: absolute;
    top: 0;
    right: 0;
}

.amm-shopping_cart-top-bar h6 {
    font-size: 24px;
    font-weight: 600;
    color: #222;
}

.amm-shopping_cart-top-bar button {
    width: 30px;
    height: 30px;
    padding: 0;
    border: 0;
    font-size: 30px;
    background: none;
}

.amm-shopping_cart-btn {
    border-top: 1px solid #ccc;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #fff;
    z-index: 99;
}

.amm-shopping_cart-btn .tota h5 {
    font-size: 18px;
    font-weight: 400;
    color: #222;
}

.amm-shopping_cart-btn .tota p {
    font-size: 18px;
    color: #2b70fa;
}

.amm-shopping_cart-btn .cart-btn a {
    margin: 5px 0;
    width: 100%;
}

/*===========================
    2.HEADER css 
===========================*/
.appie-sticky.sticky {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1010;
    background: #fff;
    box-shadow: 0 15px 30px 0 #0000000f;
    padding-top: 20px;
    padding-bottom: 20px;
    animation: sticky 1.2s;
}

.appie-header-area {
    padding-top: 30px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
}

.appie-header-area.appie-header-2-area .appie-btn-box .main-btn {
    background: #ff3e66;
    border-radius: 30px;
    border-color: #ff3e66;
}

.appie-header-area.appie-header-2-area .appie-btn-box .main-btn:hover {
    background: transparent;
    color: #ff3e66;
}

.appie-header-area.appie-header-3-area.appie-sticky.sticky {
    background: #0e1133;
}

.appie-header-area.appie-header-4-area.appie-sticky.sticky {
    background: linear-gradient(90deg, #6b1fcd 0%, #374ede 100%);
}

.appie-header-area.appie-header-page-area.appie-sticky.sticky {
    background: #0a44b9;
}

.appie-header-area.appie-header-7-area.appie-sticky.sticky {
    background: #290645;
}

.appie-header-area.appie-header-error-page {
    box-shadow: 0px 10px 20px 0px rgba(14, 17, 51, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
}

.header-nav-box .appie-btn-box {
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-nav-box .appie-btn-box {
        padding-right: 50px;
    }
}

@media (max-width: 767px) {
    .header-nav-box .appie-btn-box {
        padding-right: 30px;
    }
}

.header-nav-box .appie-btn-box .login-btn {
    color: #0e1133;
    font-size: 15px;
    font-weight: 500;
}

.header-nav-box .appie-btn-box .login-btn i {
    padding-right: 6px;
}

@media (max-width: 767px) {
    .header-nav-box .appie-btn-box .main-btn {
        display: none;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-nav-box .appie-btn-box .main-btn {
        padding: 0 10px;
        display: inline-block;
    }
}

.header-nav-box.header-nav-box-3 .appie-header-main-menu ul>li>a {
    color: #fff;
}

.header-nav-box.header-nav-box-3 .appie-header-main-menu ul>li .sub-menu li a {
    color: #0e1133;
}

.header-nav-box.header-nav-box-3 .appie-btn-box .login-btn {
    color: #fff;
}

.header-nav-box.header-nav-box-3 .appie-btn-box .main-btn {
    background: #ff6b58;
    border-color: #ff6b58;
}

.header-nav-box.header-nav-box-3 .appie-btn-box .main-btn:hover {
    background: transparent;
    color: #ff6b58;
}

.header-nav-box.header-nav-box-3 .toggle-btn {
    color: #fff;
    font-size: 20px;
}

.header-nav-box.header-nav-box-3.header-nav-box-inner-page .appie-btn-box .main-btn {
    border-color: #fff;
    background: transparent;
}

.header-nav-box.header-nav-box-3.header-nav-box-inner-page .appie-btn-box .main-btn:hover {
    background: #fff;
    color: #2b70fa;
}

.header-nav-box.header-nav-box-5 .appie-header-main-menu ul>li>a {
    color: #fff;
}

.header-nav-box.header-nav-box-5 .appie-header-main-menu ul>li .sub-menu li a {
    color: #0e1133;
}

.header-nav-box.header-nav-box-5 .appie-btn-box .login-btn {
    color: #fff;
}

.header-nav-box.header-nav-box-5 .appie-btn-box .main-btn {
    background: #f84a6e;
    border-color: #f84a6e;
}

.header-nav-box.header-nav-box-5 .appie-btn-box .main-btn:hover {
    background: transparent;
    color: #f84a6e;
}

.header-nav-box.header-nav-box-5 .toggle-btn {
    color: #fff;
    font-size: 20px;
}

.header-nav-box.header-nav-4-box .appie-header-main-menu ul>li>a:hover {
    color: #00d1b3;
}

.header-nav-box.header-nav-4-box .appie-btn-box .main-btn {
    background: #00d1b3;
    border-color: #00d1b3;
}

.header-nav-box.header-nav-4-box .appie-btn-box .main-btn:hover {
    background: transparent;
    color: #00d1b3;
}

@media (max-width: 767px) {
    .header-nav-box.header-nav-4-box .appie-btn-box ul {
        margin-right: 10px;
    }
}

.header-nav-box.header-nav-4-box .appie-btn-box ul li {
    display: inline-block;
}

.header-nav-box.header-nav-4-box .appie-btn-box ul li a {
    font-size: 18px;
    color: #0e1133;
    margin-left: 15px;
}

.header-nav-box.header-nav-4-box .appie-btn-box ul li a.cart-btn {
    position: relative;
}

.header-nav-box.header-nav-4-box .appie-btn-box ul li a.cart-btn span {
    position: absolute;
    top: -7px;
    display: inline-block;
    right: -12px;
    height: 20px;
    width: 20px;
    text-align: center;
    line-height: 20px;
    color: #fff;
    font-size: 11px;
    background: #801f82;
    border-radius: 50%;
}

.header-nav-box.header-nav-box-6 .main-btn {
    background: #9b2cfa;
    border-color: #9b2cfa;
}

.header-nav-box.header-nav-box-6 .main-btn:hover {
    background: transparent;
    color: #9b2cfa;
}

.header-nav-box.header-nav-box-6 .appie-header-main-menu ul>li>a:hover {
    color: #9b2cfa;
}

.header-nav-box.header-nav-box-6 .appie-btn-box form {
    display: inline-block;
    margin-left: 18px;
}

@media (max-width: 767px) {
    .header-nav-box.header-nav-box-6 .appie-btn-box form {
        display: none;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-nav-box.header-nav-box-6 .appie-btn-box form {
        display: inline-block;
    }
}

.header-nav-box.header-nav-box-6 .appie-btn-box form .input-box {
    position: relative;
}

.header-nav-box.header-nav-box-6 .appie-btn-box form .input-box i {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.header-nav-box.header-nav-box-6 .appie-btn-box form .input-box input {
    width: 105px;
    padding-left: 50px;
    border: 0;
    border-left: 1px solid #ddddea;
    background: transparent;
}

.header-nav-box.header-nav-box-7 .appie-header-main-menu ul>li>a {
    color: #fff;
}

.header-nav-box.header-nav-box-7 .appie-header-main-menu ul>li>a:hover {
    color: #2b70fa;
}

.header-nav-box.header-nav-box-7 .appie-header-main-menu ul>li .sub-menu li a {
    color: #505056;
}

.header-nav-box.header-nav-box-7 .appie-header-main-menu ul>li .sub-menu li a:hover {
    color: #2b70fa;
}

.header-nav-box.header-nav-box-7 .appie-btn-box .login-btn {
    color: #fff;
}

.header-nav-box.header-nav-box-7 .appie-btn-box .toggle-btn {
    color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-header-main-menu {
        display: none;
    }
}

@media (max-width: 767px) {
    .appie-header-main-menu {
        display: none;
    }
}

.appie-header-main-menu ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.appie-header-main-menu ul>li {
    display: inline-block;
    margin-right: 30px;
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-header-main-menu ul>li {
        margin-right: 14px;
    }
}

.appie-header-main-menu ul>li>a {
    font-size: 15px;
    font-weight: 500;
    color: #0e1133;
    line-height: 45px;
}

.appie-header-main-menu ul>li>a i {
    padding-left: 6px;
}

.appie-header-main-menu ul>li .sub-menu {
    position: absolute;
    left: 0;
    top: 110%;
    width: auto;
    min-width: 220px;
    max-width: 220px;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all linear 0.3s;
    z-index: 99;
    -webkit-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
    -moz-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
    box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
    list-style-type: none;
    margin: 0;
    padding: 15px 0;
    border-radius: 5px;
    text-align: left;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .appie-header-main-menu ul>li .sub-menu {
        min-width: 200px;
        max-width: 200px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-header-main-menu ul>li .sub-menu {
        min-width: 200px;
        max-width: 200px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-header-main-menu ul>li .sub-menu {
        position: relative;
        width: 100%;
        left: 0;
        top: auto;
        opacity: 1;
        visibility: visible;
        display: none;
        right: auto;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transition: all none ease-out 0s;
        -moz-transition: all none ease-out 0s;
        -ms-transition: all none ease-out 0s;
        -o-transition: all none ease-out 0s;
        transition: all none ease-out 0s;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        text-align: left;
        border-top: 0;
        transition: 0s;
        padding: 0;
    }
}

@media (max-width: 767px) {
    .appie-header-main-menu ul>li .sub-menu {
        position: relative;
        width: 100%;
        left: 0;
        top: auto;
        opacity: 1;
        visibility: visible;
        display: none;
        right: auto;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transition: all none ease-out 0s;
        -moz-transition: all none ease-out 0s;
        -ms-transition: all none ease-out 0s;
        -o-transition: all none ease-out 0s;
        transition: all none ease-out 0s;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        text-align: left;
        border-top: 0;
        transition: 0s;
        padding: 0;
    }
}

.appie-header-main-menu ul>li .sub-menu>li {
    position: relative;
    margin-left: 0;
    display: block;
}

.appie-header-main-menu ul>li .sub-menu>li .sub-menu {
    margin-left: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-header-main-menu ul>li .sub-menu>li .sub-menu {
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .appie-header-main-menu ul>li .sub-menu>li .sub-menu {
        margin-left: 0;
    }
}

.appie-header-main-menu ul>li .sub-menu>li .sub-nav-toggler {
    color: #0e1133;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.appie-header-main-menu ul>li .sub-menu>li a {
    display: block;
    padding: 0px 30px;
    position: relative;
    color: #505056;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 4px;
    margin: 0 0;
    line-height: 2.5;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .appie-header-main-menu ul>li .sub-menu>li a {
        padding: 0 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-header-main-menu ul>li .sub-menu>li a {
        padding: 0 20px;
    }
}

.appie-header-main-menu ul>li .sub-menu>li a i {
    float: right;
    font-size: 16px;
    margin-top: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-header-main-menu ul>li .sub-menu>li a i {
        display: none;
    }
}

@media (max-width: 767px) {
    .appie-header-main-menu ul>li .sub-menu>li a i {
        display: none;
    }
}

.appie-header-main-menu ul>li .sub-menu>li a .sub-nav-toggler i {
    display: inline-block;
}

.appie-header-main-menu ul>li .sub-menu>li a:hover {
    padding-left: 35px;
}

.appie-header-main-menu ul>li .sub-menu>li .sub-menu {
    right: auto;
    left: 100%;
    top: 50%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-header-main-menu ul>li .sub-menu>li .sub-menu {
        padding-left: 30px;
        -webkit-transition: all 0s ease-out 0s;
        -moz-transition: all 0s ease-out 0s;
        -ms-transition: all 0s ease-out 0s;
        -o-transition: all 0s ease-out 0s;
        transition: all 0s ease-out 0s;
    }
}

@media (max-width: 767px) {
    .appie-header-main-menu ul>li .sub-menu>li .sub-menu {
        padding-left: 30px;
        -webkit-transition: all 0s ease-out 0s;
        -moz-transition: all 0s ease-out 0s;
        -ms-transition: all 0s ease-out 0s;
        -o-transition: all 0s ease-out 0s;
        transition: all 0s ease-out 0s;
    }
}

.appie-header-main-menu ul>li .sub-menu>li .sub-menu li {
    position: relative;
}

.appie-header-main-menu ul>li .sub-menu>li .sub-menu li .sub-menu {
    right: auto;
    left: 100%;
    top: 50%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.appie-header-main-menu ul>li .sub-menu>li .sub-menu li:hover .sub-menu {
    top: 0%;
    opacity: 1;
    visibility: visible;
}

.appie-header-main-menu ul>li .sub-menu>li:hover .sub-menu {
    top: 0%;
    opacity: 1;
    visibility: visible;
}

.appie-header-main-menu ul>li .sub-menu>li:hover .sub-nav-toggler {
    color: #2b70fa;
}

.appie-header-main-menu ul>li .sub-menu>li:hover>a {
    color: #2b70fa;
}

.appie-header-main-menu ul>li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

@-webkit-keyframes sticky {
    0% {
        top: -200px;
    }

    100% {
        top: 0;
    }
}

@keyframes sticky {
    0% {
        top: -200px;
    }

    100% {
        top: 0;
    }
}

.appie-header-8-area .header-nav-box .appie-btn-box .main-btn {
    background: #db0f30;
    border-color: #db0f30;
}

.appie-header-8-area .header-nav-box .appie-btn-box .main-btn:hover {
    background: #fff;
    color: #db0f30;
}

.appie-header-8-area .header-nav-box .appie-header-main-menu ul>li a:hover {
    color: #db0f30;
}

/*===========================
    3.APPIE HERO css 
===========================*/
.appie-hero-area {
    padding-top: 200px;
    padding-bottom: 110px;
    overflow: hidden;
    position: relative;
    z-index: 10;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.appie-hero-area.appie-hero-3-area {
    background-image: url(../images/hero-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 180px;
    padding-bottom: 0px;
    overflow: visible;
    z-index: 15;
}

.appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
    color: #fff;
    font-size: 70px;
    line-height: 80px;
    padding: 0 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
        font-size: 54px;
        line-height: 64px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
        font-size: 50px;
        line-height: 60px;
    }
}

@media (max-width: 767px) {
    .appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
        font-size: 36px;
        line-height: 46px;
        padding: 0;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
        font-size: 46px;
        line-height: 56px;
        padding: 0;
    }
}

.appie-hero-area.appie-hero-3-area .appie-hero-content p {
    color: #fff;
    opacity: 0.5;
    font-size: 18px;
    line-height: 28px;
}

.appie-hero-area.appie-hero-3-area .appie-hero-content .hero-btns {
    margin-top: 35px;
}

.appie-hero-area.appie-hero-3-area .appie-hero-content .hero-btns .main-btn {
    border-color: #ff6b58;
    color: #ff6b58;
    background: transparent;
    margin-right: 14px;
}

.appie-hero-area.appie-hero-3-area .appie-hero-content .hero-btns .appie-video-popup {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .appie-hero-area.appie-hero-3-area .appie-hero-content .hero-btns .appie-video-popup {
        margin-top: 20px;
    }
}

.appie-hero-area.appie-hero-3-area .appie-hero-content .hero-btns .appie-video-popup i {
    height: 45px;
    width: 45px;
    text-align: center;
    line-height: 42px;
    border-radius: 50%;
    border: 2px solid #fff;
    color: #fff;
    margin-right: 10px;
}

.appie-hero-area.appie-hero-3-area .appie-hero-content .thumb {
    margin-bottom: -210px;
}

@media (max-width: 767px) {
    .appie-hero-area.appie-hero-3-area .appie-hero-content .thumb {
        display: none;
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-hero-area.appie-hero-3-area .appie-hero-content .thumb {
        display: block;
        margin-bottom: -210px;
    }
}

.appie-hero-area.appie-hero-3-area.appie-hero-5-area {
    background-color: #eef1f6;
    background-image: url(../images/hero-bg-2.png);
    padding-top: 280px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-hero-area.appie-hero-3-area.appie-hero-5-area {
        padding-top: 120px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-hero-area.appie-hero-3-area.appie-hero-5-area {
        padding-top: 160px;
    }
}

@media (max-width: 767px) {
    .appie-hero-area.appie-hero-3-area.appie-hero-5-area {
        padding-top: 120px;
        padding-bottom: 100px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-hero-area.appie-hero-3-area.appie-hero-5-area {
        padding-top: 120px;
        padding-bottom: 0px;
    }
}

.appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .thumb {
    margin-bottom: -110px;
}

.appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .appie-title {
    font-weight: 700;
    font-size: 70px;
    line-height: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .appie-title {
        font-size: 50px;
        line-height: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .appie-title {
        font-size: 40px;
        line-height: 50px;
    }
}

@media (max-width: 767px) {
    .appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .appie-title {
        font-size: 30px;
        line-height: 40px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .appie-title {
        font-size: 36px;
        line-height: 46px;
    }
}

.appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .hero-btns .appie-video-popup i {
    border-color: rgba(255, 255, 255, 0.2);
}

.appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .hero-btns .main-btn {
    border-color: rgba(255, 255, 255, 0.2);
    color: #fff;
}

.appie-hero-area.appie-hero-3-area.appie-hero-5-area .appie-hero-content .hero-btns .main-btn:hover {
    background: #f84a6e;
    color: #fff;
}

.appie-hero-area .hero-shape-1 {
    position: absolute;
    left: 0;
    top: 250px;
    z-index: -1;
    animation: linear 20s animationFramesOne infinite;
}

.appie-hero-area .hero-shape-2 {
    position: absolute;
    left: 80px;
    z-index: -1;
    top: 480px;
    animation: linear 25s animationFramesThree infinite;
}

.appie-hero-area .hero-shape-3 {
    position: absolute;
    bottom: 120px;
    right: 320px;
    z-index: -1;
    animation: linear 25s animationFramesFive infinite;
}

.appie-hero-area.appie-hero-4-area {
    background: #f9f9f9;
    background-image: url(../img/banner/banner_All_Just3.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 200px;
    padding-bottom: 60px;
}

.appie-hero-area.appie-hero-6-area {
    background-image: url(../images/hero-bg-3.jpg);
}

.appie-hero-area.appie-hero-7-area {
    background-image: url(../images/hero-bg-4.png);
}

.appie-hero-area.appie-hero-8-area {
    position: relative;
    z-index: 10;
}

.appie-hero-area.appie-hero-8-area .home-8-shape-1 {
    position: absolute;
    left: 0;
    bottom: 100px;
    z-index: -1;
}

.appie-hero-area.appie-hero-8-area .home-8-shape-2 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.appie-hero-area.appie-hero-8-area .home-8-shape-3 {
    position: absolute;
    top: 400px;
    right: 290px;
    z-index: -1;
}

.appie-hero-area.appie-hero-8-area .home-8-shape-4 {
    position: absolute;
    bottom: 195px;
    left: 195px;
    z-index: -1;
}

.appie-hero-area.appie-hero-8-area .appie-hero-content-8 .appie-title {
    font-size: 70px;
    line-height: 80px;
    font-weight: 700;
    background: linear-gradient(90deg, #fb2d4e 25%, #4e88ff 75%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-hero-area.appie-hero-8-area .appie-hero-content-8 .appie-title {
        font-size: 56px;
        line-height: 66px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-hero-area.appie-hero-8-area .appie-hero-content-8 .appie-title {
        font-size: 42px;
        line-height: 66px;
    }
}

@media (max-width: 767px) {
    .appie-hero-area.appie-hero-8-area .appie-hero-content-8 .appie-title {
        font-size: 32px;
        line-height: 44px;
    }
}

.appie-hero-thumb-6 .thumb {
    position: relative;
    z-index: 10;
}

.appie-hero-thumb-6 .thumb .back-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-hero-content {
        margin-bottom: 50px;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .appie-hero-content {
        margin-bottom: 50px;
        text-align: center;
    }
}

.appie-hero-content>span {
    color: #2b70fa;
    font-size: 14px;
    font-weight: 500;
}

.appie-hero-content .appie-title {
    font-size: 60px;
    line-height: 70px;
    margin-top: 10px;
    margin-bottom: 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-hero-content .appie-title {
        font-size: 48px;
        line-height: 58px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-hero-content .appie-title {
        font-size: 48px;
        line-height: 58px;
    }
}

@media (max-width: 767px) {
    .appie-hero-content .appie-title {
        font-size: 28px;
        line-height: 38px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-hero-content .appie-title {
        font-size: 36px;
        line-height: 46px;
    }
}

.appie-hero-content p {
    font-size: 18px;
    line-height: 28px;
}

@media (max-width: 767px) {
    .appie-hero-content p {
        font-size: 15px;
        line-height: 26px;
    }
}

.appie-hero-content ul {
    margin: 41px 0 0;
    padding: 0;
    list-style-type: none;
}

.appie-hero-content ul li {
    display: inline-block;
    margin-right: 8px;
}

@media (max-width: 767px) {
    .appie-hero-content ul li {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-hero-content ul li {
        margin-bottom: 0px;
    }
}

.appie-hero-content ul li a {
    background: #0e1133;
    color: #fff;
    line-height: 40px;
    padding: 0 23px;
    border-radius: 6px;
    border: 2px solid #0e1133;
}

.appie-hero-content ul li a i {
    padding-right: 6px;
}

.appie-hero-content ul li a:hover {
    background: transparent;
    color: #0e1133;
}

.appie-hero-content ul li a.item-2 {
    background: transparent;
    color: #0e1133;
}

.appie-hero-content ul li a.item-2:hover {
    background: #0e1133;
    color: #fff;
}

.appie-hero-content.appie-hero-content-4 {
    padding-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-hero-content.appie-hero-content-4 {
        padding-right: 0;
    }
}

@media (max-width: 767px) {
    .appie-hero-content.appie-hero-content-4 {
        padding-right: 0;
    }
}

.appie-hero-content.appie-hero-content-4>span {
    color: #00d1b3;
}

.appie-hero-content.appie-hero-content-4 .appie-title {
    font-size: 70px;
    font-weight: 700;
    line-height: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-hero-content.appie-hero-content-4 .appie-title {
        font-size: 60px;
        line-height: 70px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-hero-content.appie-hero-content-4 .appie-title {
        font-size: 50px;
        line-height: 60px;
    }
}

@media (max-width: 767px) {
    .appie-hero-content.appie-hero-content-4 .appie-title {
        font-size: 30px;
        line-height: 40px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-hero-content.appie-hero-content-4 .appie-title {
        font-size: 40px;
        line-height: 50px;
    }
}

.appie-hero-content.appie-hero-content-4 a.main-btn {
    background-color: #00ceff;
    border: 2px solid #00ceff;
    color: #ffffff;
    line-height: 55px;
    padding: 0 40px;
    font-size: 16px;
    margin-top: 40px;
}

.appie-hero-content.appie-hero-content-4 a.main-btn:hover {
    background: #048aac;
    border: 2px solid #048aac;
    color: #fff;
}

.appie-hero-content.appie-hero-content-6>span {
    color: #9b2cfa;
}

.appie-hero-content.appie-hero-content-6 .appie-title {
    font-weight: 700;
    font-size: 70px;
    line-height: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-hero-content.appie-hero-content-6 .appie-title {
        font-size: 60px;
        line-height: 70px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-hero-content.appie-hero-content-6 .appie-title {
        font-size: 50px;
        line-height: 60px;
    }
}

@media (max-width: 767px) {
    .appie-hero-content.appie-hero-content-6 .appie-title {
        font-size: 36px;
        line-height: 46px;
    }
}

.appie-hero-content.appie-hero-content-6 ul li a {
    background: #9b2cfa;
    border-color: #9b2cfa;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
}

.appie-hero-content.appie-hero-content-6 ul li a span {
    line-height: 20px;
}

.appie-hero-content.appie-hero-content-6 ul li a span span {
    font-size: 16px;
    font-weight: 700;
    display: block;
    line-height: 20px;
}

.appie-hero-content.appie-hero-content-6 ul li a i {
    font-size: 30px;
}

.appie-hero-content.appie-hero-content-6 ul li a:hover {
    background: #fff;
    color: #0e1133;
    border-color: #fff;
}

.appie-hero-content.appie-hero-content-6 ul li a.item-2 {
    background: #fff;
    border-color: #fff;
}

.appie-hero-content.appie-hero-content-6 ul li a.item-2:hover {
    background: #9b2cfa;
    border-color: #9b2cfa;
    color: #fff;
}

.appie-hero-content.appie-hero-content-6.appie-hero-content-7 .appie-title {
    color: #fff;
}

.appie-hero-content.appie-hero-content-6.appie-hero-content-7 p {
    color: #fff;
    opacity: 0.5;
}

.appie-hero-content.appie-hero-content-6.appie-hero-content-7 ul li a {
    background: #fff;
    color: #0e1133;
    border-color: #fff;
}

.appie-hero-content.appie-hero-content-6.appie-hero-content-7 ul li a:hover {
    background: transparent;
    border-color: #54386a;
    color: #fff;
}

.appie-hero-content.appie-hero-content-6.appie-hero-content-7 ul li a.item-2 {
    background: transparent;
    color: #fff;
    border-color: #54386a;
}

.appie-hero-content.appie-hero-content-6.appie-hero-content-7 ul li a.item-2:hover {
    border-color: #fff;
    background: #fff;
    color: #0e1133;
}

.appie-hero-thumb {
    position: relative;
    z-index: 10;
    text-align: center;
}

.appie-hero-thumb .thumb-2 {
    position: absolute;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 30px 70px 0px rgba(68, 1, 15, 0.2);
}

.appie-hero-thumb::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    height: 500px;
    width: 500px;
    transform: translate(-50%, -50%);
    background: #ff3e66;
    border-radius: 50%;
    z-index: -1;
}

.appie-hero-thumb.appie-hero-thumb-4::before {
    height: 160px;
    width: 160px;
    top: 200px;
    left: 70px;
    background: #801f82;
    transform: translate(0%, 0%);
}

.appie-hero-thumb.appie-hero-thumb-4 .hero-dot {
    position: absolute;
    right: 70px;
    bottom: 115px;
    z-index: -1;
}

.appie-hero-area-2 {
    padding-top: 290px;
    padding-bottom: 170px;
    background: #eef1f6;
    position: relative;
    overflow: hidden;
    z-index: 10;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-hero-area-2 {
        padding-top: 190px;
        padding-bottom: 120px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-hero-area-2 {
        padding-top: 190px;
        padding-bottom: 120px;
    }
}

@media (max-width: 767px) {
    .appie-hero-area-2 {
        padding-top: 190px;
        padding-bottom: 150px;
    }
}

.appie-hero-area-2 .appie-hero-thumb-3 {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 48%;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .appie-hero-area-2 .appie-hero-thumb-3 {
        width: 60%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-hero-area-2 .appie-hero-thumb-3 {
        width: 40%;
    }
}

@media (max-width: 767px) {
    .appie-hero-area-2 .appie-hero-thumb-3 {
        width: 36%;
    }
}

.appie-hero-area-2 .hero-shape-1 {
    position: absolute;
    top: 250px;
    left: 130px;
    animation: linear 20s animationFramesOne infinite;
    z-index: -1;
}

.appie-hero-area-2 .hero-shape-2 {
    position: absolute;
    bottom: 290px;
    left: 40px;
    animation: linear 20s animationFramesFour infinite;
    z-index: -1;
}

.appie-hero-area-2 .hero-shape-3 {
    position: absolute;
    top: 480px;
    right: 240px;
    animation: linear 20s animationFramesFive infinite;
    z-index: -1;
}

.appie-hero-area-2 .hero-shape-4 {
    position: absolute;
    top: 210px;
    right: 0px;
    animation: linear 20s animationFramesThree infinite;
    z-index: -1;
}

.appie-hero-content-2>span {
    font-size: 16px;
    font-weight: 500;
    color: #ff3e66;
    position: relative;
}

.appie-hero-content-2>span::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 130px;
    height: 70px;
    background-image: url(../images/hero-line.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.appie-hero-content-2 .appie-title {
    font-size: 64px;
    line-height: 70px;
    font-weight: 400;
    margin-top: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-hero-content-2 .appie-title {
        font-size: 50px;
        line-height: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-hero-content-2 .appie-title {
        font-size: 50px;
        line-height: 60px;
    }
}

@media (max-width: 767px) {
    .appie-hero-content-2 .appie-title {
        font-size: 30px;
        line-height: 40px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-hero-content-2 .appie-title {
        font-size: 40px;
        line-height: 50px;
    }
}

.appie-hero-content-2 .appie-title span {
    font-weight: 900;
}

.appie-hero-content-2 p {
    font-size: 18px;
    color: #0e1133;
    margin-top: 22px;
}

.appie-hero-content-2 .input-box {
    position: relative;
    margin-top: 40px;
    width: 400px;
}

@media (max-width: 767px) {
    .appie-hero-content-2 .input-box {
        width: 100%;
    }
}

.appie-hero-content-2 .input-box>i {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    color: #c3c3cc;
}

.appie-hero-content-2 .input-box input {
    width: 100%;
    height: 60px;
    border-radius: 40px;
    border: 0;
    border-left: 3px solid #ff3e66;
    padding-left: 55px;
    box-shadow: -3px 0px 0px 0px rgba(255, 62, 102, 0.004),
        0px 30px 30px 0px rgba(14, 17, 51, 0.1);
}

.appie-hero-content-2 .input-box button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    background: #fff;
    border-radius: 50%;
    border: 0;
    color: #ff3e66;
}

.appie-hero-content-2 .hero-users {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.appie-hero-content-2 .hero-users>span {
    margin-left: 20px;
    font-size: 26px;
    font-weight: 900;
    color: #0e1133;
    width: 60px;
    display: inline-block;
    line-height: 16px;
}

.appie-hero-content-2 .hero-users>span span {
    font-size: 13px;
    font-weight: 500;
    color: #505056;
    display: inline-block;
    line-height: 13px;
}

.appie-page-title-area {
    height: 450px;
    background-image: url(../images/page-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}

.appie-page-title-area.appie-page-service-title-area {
    height: 650px;
    background-image: url(../images/page-title-bg.jpg);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-page-title-area.appie-page-service-title-area {
        height: 500px;
    }
}

@media (max-width: 767px) {
    .appie-page-title-area.appie-page-service-title-area {
        height: 400px;
    }
}

.appie-page-title-area.appie-page-service-title-area .appie-page-title-item .thumb {
    right: 110px;
    width: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-page-title-area.appie-page-service-title-area .appie-page-title-item .thumb {
        right: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-page-title-area.appie-page-service-title-area .appie-page-title-item .thumb {
        display: none;
    }
}

@media (max-width: 767px) {
    .appie-page-title-area.appie-page-service-title-area .appie-page-title-item .thumb {
        display: none;
    }
}

.appie-page-title-item {
    margin-top: 80px;
    position: relative;
}

.appie-page-title-item .thumb {
    position: absolute;
    right: 0;
    bottom: -100px;
    width: 160px;
}

@media (max-width: 767px) {
    .appie-page-title-item .thumb {
        display: none;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-page-title-item .thumb {
        display: block;
    }
}

.appie-page-title-item span {
    color: #fff;
    font-weight: 500;
    opacity: 0.7;
    padding-bottom: 14px;
}

.appie-page-title-item .title {
    color: #fff;
    font-size: 44px;
    font-weight: 700;
}

@media (max-width: 767px) {
    .appie-page-title-item .title {
        font-size: 26px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-page-title-item .title {
        font-size: 32px;
    }
}

.appie-page-title-item nav {
    display: inline-block;
    margin-top: 20px;
}

.appie-page-title-item nav ol {
    background: transparent;
    padding: 0;
}

.appie-page-title-item nav ol li a {
    color: #fff;
    opacity: 0.8;
}

.appie-page-title-item nav ol li.active {
    color: #fff;
    opacity: 0.8;
}

/*===========================
    4.APPIE SERVOCE css 
===========================*/
.appie-service-area {
    background: #eef1f6;
    position: relative;
    background-position: 92% 100%;
    background-repeat: no-repeat;
    background-size: auto;
}

.appie-service-area.appie-service-3-area {
    background-color: #00d1b3;
}

@media (max-width: 767px) {
    .appie-service-area.appie-service-3-area {
        padding-top: 100px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-service-area.appie-service-3-area {
        padding-top: 190px;
    }
}

.appie-single-service {
    padding: 50px 30px 43px;
    background: #fff;
    border-radius: 6px;
    transition: all linear 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-single-service {
        padding: 50px 15px 43px;
    }
}

.appie-single-service .icon {
    display: inline-block;
    border-radius: 20px;
    height: 70px;
    width: 70px;
    text-align: center;
    line-height: 70px;
    background-color: #1c435d;
    box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
    position: relative;
}

.appie-single-service .icon>span {
    position: absolute;
    right: -10px;
    top: -10px;
    height: 30px;
    width: 30px;
    background: #2b70fa;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0px 3px 6px 0px rgba(14, 17, 51, 0.1);
    line-height: 28px;
    color: #fff;
}

.appie-single-service .appie-title {
    font-size: 20px;
    font-weight: 700;
    padding-top: 33px;
    padding-bottom: 10px;
}

.appie-single-service:hover {
    box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
    transform: translateY(-15px);
}

.appie-single-service.item-2 .icon {
    background: linear-gradient(135deg, #f7b8af 0%, #f8452d 100%);
}

.appie-single-service.item-2 .icon span {
    background: #f8452d;
}

.appie-single-service.item-3 .icon {
    background: linear-gradient(135deg, #a0eac2 0%, #31c369 100%);
}

.appie-single-service.item-3 .icon span {
    background: #31c369;
}

.appie-single-service.item-4 .icon {
    background: linear-gradient(135deg, #d8aaff 0%, #9b2cfa 100%);
}

.appie-single-service.item-4 .icon span {
    background: #9b2cfa;
}

.appie-single-service.appie-single-services-3 {
    background: transparent;
}

.appie-single-service.appie-single-services-3 .icon {
    border-radius: 50%;
}

.appie-single-service.appie-single-services-3 a {
    color: #505056;
    font-size: 14px;
    font-weight: 500;
    margin-top: 25px;
}

.appie-single-service.appie-single-services-3 a:hover {
    color: #ff6b58;
}

.appie-single-service.appie-single-services-3.item-2 {
    background: linear-gradient(0deg,
            rgba(39, 153, 246, 0.08) 0%,
            rgba(255, 255, 255, 0.08) 100%);
}

.appie-single-service.appie-single-services-3.item-2 .icon {
    background-image: -moz-linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
    background-image: -webkit-linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
    background-image: -ms-linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
    background-image: linear-gradient(110deg, #2799f6 0%, #76c1ff 100%);
}

.appie-single-service.appie-single-services-3.item-3 .icon {
    background-image: -moz-linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
    background-image: -webkit-linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
    background-image: -ms-linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
    background-image: linear-gradient(110deg, #f2335b 0%, #ff718f 100%);
}

.appie-single-service.appie-single-services-3.item-4 {
    background: linear-gradient(0deg,
            rgba(119, 89, 237, 0.08) 0%,
            rgba(255, 255, 255, 0.08) 100%);
}

.appie-single-service.appie-single-services-3.item-4 .icon {
    background-image: -moz-linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
    background-image: -webkit-linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
    background-image: -ms-linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
    background-image: linear-gradient(110deg, #7759ed 0%, #ac97ff 100%);
}

.appie-single-service.appie-single-services-3.appie-single-services-5 a:hover {
    color: #801f82;
}

@media (max-width: 767px) {
    .appie-services-2-area .appie-section-title {
        text-align: center !important;
    }
}

.appie-single-service-2 {
    border: 1px solid #eef0f4;
    border-radius: 10px;
    padding: 48px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media (max-width: 767px) {
    .appie-single-service-2 {
        text-align: center;
        padding: 20px;
    }
}

.appie-single-service-2 .icon i {
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    background: #2eacfc;
    color: #fff;
    border-radius: 50%;
}

.appie-single-service-2 .title {
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 10px;
}

.appie-single-service-2 a {
    font-size: 14px;
    font-weight: 500;
    color: #505056;
    margin-top: 23px;
}

.appie-single-service-2 a:hover {
    color: #ff3e66;
}

.appie-single-service-2:hover {
    box-shadow: 0px -3px 0px 0px rgba(234, 76, 137, 0.004),
        0px 40px 40px 0px rgba(14, 17, 51, 0.1);
    border-top: 3px solid #2eacfc;
    transform: translateY(-10px);
}

.appie-single-service-2.item-2 .icon i {
    background: #ea4c89;
}

.appie-single-service-2.item-2:hover {
    border-top-color: #ea4c89;
}

.appie-single-service-2.item-3 .icon i {
    background: #3578ff;
}

.appie-single-service-2.item-3:hover {
    border-top-color: #3578ff;
}

.appie-single-service-2.item-4 .icon i {
    background: #f67741;
}

.appie-single-service-2.item-4:hover {
    border-top-color: #f67741;
}

.appie-single-service-2.item-5 .icon i {
    background: #6a88f7;
}

.appie-single-service-2.item-5:hover {
    border-top-color: #6a88f7;
}

.appie-single-service-2.item-6 .icon i {
    background: #3ab094;
}

.appie-single-service-2.item-6:hover {
    border-top-color: #3ab094;
}

.appie-single-service-2.appie-single-service-about {
    border-radius: 0;
    padding: 0 90px 30px 0;
    border: 0;
    transition: all linear 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-single-service-2.appie-single-service-about {
        padding-right: 30px;
    }
}

@media (max-width: 767px) {
    .appie-single-service-2.appie-single-service-about {
        padding-right: 50px;
        padding-left: 50px;
    }
}

.appie-single-service-2.appie-single-service-about .icon i {
    background-color: #f1f7fe;
    color: #2b70fa;
    border-radius: 35% 65% 50% 50% / 59% 50% 50% 41%;
    font-size: 20px;
}

.appie-single-service-2.appie-single-service-about:hover {
    box-shadow: none;
    margin-top: 20px;
}

.appie-single-service-2.appie-single-service-about.item-2 .icon i {
    color: #db0f30;
    background-color: #fef3f5;
    border-radius: 50% 50% 50% 50% / 50% 75% 25% 50%;
}

.appie-single-service-2.appie-single-service-about.item-3 .icon i {
    color: #9b2cfa;
    background-color: #f7eeff;
    border-radius: 25% 75% 50% 50% / 50% 50% 50% 50%;
}

.appie-single-service-2.appie-single-service-about.item-4 .icon i {
    color: #f8452d;
    background-color: #fef0ee;
    border-radius: 40% 60% 40% 60% / 40% 60% 40% 60%;
}

.appie-single-service-2.appie-single-service-about.item-5 .icon i {
    color: #31c369;
    background-color: #f4fcf7;
    border-radius: 33% 67% 50% 50% / 61% 72% 28% 39%;
}

.appie-single-service-2.appie-single-service-about.item-6 .icon i {
    color: #f6ac0e;
    background-color: #fef7e7;
    border-radius: 21% 79% 58% 42% / 50% 46% 54% 50%;
}

.appie-services-6-area .appie-section-title .main-btn:hover {
    background: #9b2cfa;
    border-color: #9b2cfa;
}

.appie-services-6-area .appie-single-service-2 a:hover {
    color: #9b2cfa;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .service-details-sidebar {
        margin-right: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service-details-sidebar {
        margin-right: 0;
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .service-details-sidebar {
        margin-right: 0;
        margin-bottom: 30px;
    }
}

.service-details-sidebar .service-category-widget {
    border-radius: 6px;
    box-shadow: 0px 30px 50px 0px rgba(14, 17, 51, 0.1);
    padding: 30px;
    margin-bottom: 30px;
}

.service-details-sidebar .service-category-widget ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.service-details-sidebar .service-category-widget ul li {
    margin-bottom: 20px;
}

.service-details-sidebar .service-category-widget ul li i {
    color: #2b70fa;
    width: 15px;
    margin-right: 15px;
}

.service-details-sidebar .service-category-widget ul li:last-child {
    margin-bottom: 0;
}

.service-details-sidebar .service-download-widget {
    border-radius: 6px;
    margin-top: 10px;
    box-shadow: 0px 0px 50px 0px rgba(14, 17, 51, 0.1);
}

.service-details-sidebar .service-download-widget a {
    padding: 13px 30px;
    color: #505056;
}

.service-details-sidebar .service-download-widget a:hover {
    color: #2b70fa;
}

.service-details-sidebar .service-download-widget i {
    color: #2b70fa;
    padding-right: 15px;
}

.service-details-content .thumb {
    border-radius: 10px;
    overflow: hidden;
}

.service-details-content .content {
    padding-top: 34px;
}

.service-details-content .content .title {
    font-size: 30px;
    margin-bottom: 18px;
}

.service-details-content .content span {
    font-size: 20px;
    line-height: 30px;
    color: #0e1133;
    padding-top: 34px;
    padding-bottom: 26px;
}

.appie-services-8-area .service-thumb {
    margin-left: -50px;
    margin-right: -100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-services-8-area .service-thumb {
        display: none;
    }
}

@media (max-width: 767px) {
    .appie-services-8-area .service-thumb {
        display: none;
    }
}

/*===========================
    5.APPIE FEATURES css 
===========================*/
.appie-features-area {
    position: relative;
    z-index: 10;
    overflow: hidden;
}

.appie-features-area .features-shape-1 {
    position: absolute;
    left: 560px;
    top: 130px;
    animation: animationFramesOne 20s linear infinite;
    z-index: -1;
}

.appie-features-area .features-shape-2 {
    position: absolute;
    left: 500px;
    bottom: 0px;
    animation: animationFramesThree 20s linear infinite;
    z-index: -1;
}

.appie-features-area .features-shape-3 {
    position: absolute;
    right: 270px;
    bottom: 0;
    animation: animationFramesFive 20s linear infinite;
    z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-features-tabs-btn .nav {
        flex-direction: row !important;
        justify-content: center;
        margin-bottom: 40px;
    }
}

@media (max-width: 767px) {
    .appie-features-tabs-btn .nav {
        flex-direction: row !important;
        justify-content: center;
        margin-bottom: 40px;
    }
}

.appie-features-tabs-btn .nav a {
    border-right: 3px solid #e8eaef;
    border-radius: 0;
    font-size: 15px;
    font-weight: 500;
    color: #505056;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-features-tabs-btn .nav a {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media (max-width: 767px) {
    .appie-features-tabs-btn .nav a {
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 20px;
    }
}

.appie-features-tabs-btn .nav a i {
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 35px;
    background: #f1f2f7;
    color: #63636a;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
}

.appie-features-tabs-btn .nav a.active {
    border-right-color: #2b70fa;
    background: transparent;
    color: #2b70fa;
}

.appie-features-tabs-btn .nav a.active i {
    color: #fff;
    background: #2b70fa;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-features-content {
        padding-top: 40px;
    }
}

@media (max-width: 767px) {
    .appie-features-content {
        padding-top: 30px;
    }
}

.appie-features-content span {
    font-size: 14px;
    font-weight: 500;
    color: #2b70fa;
    margin-bottom: 8px;
}

.appie-features-content .title {
    font-size: 44px;
    line-height: 54px;
    margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-features-content .title {
        font-size: 36px;
        line-height: 44px;
    }
}

@media (max-width: 767px) {
    .appie-features-content .title {
        font-size: 30px;
        line-height: 40px;
    }
}

.appie-features-content p {
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 30px;
}

.appie-features-area-2 {
    background: #0e1133;
    position: relative;
    overflow: hidden;
    z-index: 10;
}

.appie-features-area-2 .features-shape-1 {
    position: absolute;
    left: 240px;
    top: 240px;
    z-index: -1;
    animation: linear 20s animationFramesThree infinite;
}

.appie-features-area-2 .features-shape-2 {
    position: absolute;
    right: 190px;
    top: 160px;
    z-index: -1;
    animation: linear 20s animationFramesFive infinite;
}

.appie-features-area-2 .features-shape-3 {
    position: absolute;
    right: 160px;
    bottom: 260px;
    z-index: -1;
    animation: linear 20s animationFramesOne infinite;
}

.appie-features-area-2.appie-features-area-5 {
    background: #fff;
}

.appie-features-box-item {
    border: 1px solid #202234;
    padding: 25px 30px 30px 70px;
    border-radius: 6px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    margin-bottom: 20px;
    margin-left: 100px;
    margin-right: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-features-box-item {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-features-box-item {
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media (max-width: 767px) {
    .appie-features-box-item {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 60px;
    }
}

.appie-features-box-item .title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-features-box-item .title {
        font-size: 20px;
    }
}

@media (max-width: 767px) {
    .appie-features-box-item .title {
        font-size: 20px;
    }
}

.appie-features-box-item .title::before {
    position: absolute;
    content: "";
    left: -40px;
    top: 0;
    height: 30px;
    width: 30px;
    background: #00ceff;
    border: 6px solid #2a182d;
    border-radius: 50%;
}

.appie-features-box-item p {
    font-size: 14px;
    opacity: 0.6;
    color: #fff;
    margin-top: 3px;
}

.appie-features-box-item:hover {
    border-color: #00ceff;
}

.appie-features-box-item.item-2 .title::before {
    background: #00f4c4;
    border-color: #15233d;
}

.appie-features-box-item.item-2:hover {
    border-color: #00f4c4;
}

.appie-features-box-item.item-3 .title::before {
    background: #03d1b3;
    border-color: #15262c;
}

.appie-features-box-item.item-3:hover {
    border-color: #03d1b3;
}

.appie-features-box-item.appie-features-box-5-item .title {
    color: #0e1133;
}

.appie-features-box-item.appie-features-box-5-item .title::before {
    border-color: rgba(9, 8, 8, 0.21);
}

.appie-features-box-item.appie-features-box-5-item p {
    color: #505056;
    opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-features-thumb {
        text-align: center;
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .appie-features-thumb {
        text-align: center;
        margin-top: 50px;
    }
}

.appie-features-6-area .appie-features-tabs-btn .nav a.active {
    color: #9b2cfa;
    border-right-color: #9b2cfa;
}

.appie-features-6-area .appie-features-tabs-btn .nav a.active i {
    background: #9b2cfa;
}

.appie-features-6-area .appie-features-content>span {
    color: #9b2cfa;
}

.appie-features-6-area .appie-features-content a {
    background: #9b2cfa;
    border-color: #9b2cfa;
}

.appie-features-6-area .appie-features-content a:hover {
    background: #fff;
    color: #9b2cfa;
}

/*===========================
    6.APPIE TRAFFICE css 
===========================*/
.appie-traffic-area {
    position: relative;
    overflow: hidden;
    z-index: 15;
}

.appie-traffic-area .traffic-thumb {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 42%;
}

@media (max-width: 767px) {
    .appie-traffic-area .traffic-thumb {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-traffic-area .traffic-thumb {
        display: none;
    }
}

.appie-traffic-title {
    padding-right: 220px;
    margin-bottom: 43px;
}

@media (max-width: 767px) {
    .appie-traffic-title {
        padding-right: 0;
    }
}

.appie-traffic-title span {
    font-size: 14px;
    font-weight: 500;
    color: #2b70fa;
}

.appie-traffic-title .title {
    font-size: 44px;
    line-height: 54px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-traffic-title .title {
        font-size: 36px;
        line-height: 46px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-traffic-title .title {
        font-size: 36px;
        line-height: 46px;
    }
}

@media (max-width: 767px) {
    .appie-traffic-title .title {
        font-size: 30px;
        line-height: 40px;
    }
}

.appie-traffic-service {
    position: relative;
    padding-left: 40px;
    padding-right: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-traffic-service {
        padding-right: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-traffic-service {
        margin-bottom: 30px;
        padding-right: 50px;
    }
}

@media (max-width: 767px) {
    .appie-traffic-service {
        margin-bottom: 30px;
        padding-right: 0px;
    }
}

.appie-traffic-service .icon {
    position: absolute;
    left: 0;
    top: 5px;
}

.appie-traffic-service .icon i {
    height: 26px;
    width: 26px;
    text-align: center;
    line-height: 26px;
    border-radius: 50%;
    background: linear-gradient(135deg, #84bcfa 0%, #2b70fa 100%);
    color: #fff;
    font-size: 14px;
    box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
}

.appie-traffic-service .title {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 7px;
}

.appie-traffic-service p {
    font-size: 15px;
}

.appie-traffic-service.item-2 .icon i {
    background: linear-gradient(135deg, #f7b8af 0%, #f8452d 100%);
}

.appie-traffic-service.item-3 .icon i {
    background: linear-gradient(135deg, #a0eac2 0%, #31c369 100%);
}

.appie-traffic-service.item-4 .icon i {
    background: linear-gradient(135deg, #d8aaff 0%, #9b2cfa 100%);
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .traffic-btn {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .traffic-btn {
        text-align: center;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .traffic-btn {
        text-align: left;
    }
}

.traffic-btn a.main-btn {
    background: transparent;
    color: #0e1133;
    border: 2px solid #e7eaef;
}

.traffic-btn a.main-btn i {
    padding-left: 6px;
}

.traffic-btn a.main-btn:hover {
    background: #2b70fa;
    color: #fff;
}

/*===========================
    6.APPIE TRAFFICE css 
===========================*/
.appie-testimonial-area {
    background-image: url(../images/testimonial-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .author-info,
.appie-testimonial-slider .appie-testimonial-item .author-info {
    margin-bottom: 15px;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .author-info img,
.appie-testimonial-slider .appie-testimonial-item .author-info img {
    display: inline-block;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .author-info .title,
.appie-testimonial-slider .appie-testimonial-item .author-info .title {
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    padding-top: 10px;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .author-info span,
.appie-testimonial-slider .appie-testimonial-item .author-info span {
    font-size: 14px;
    color: #fff;
    opacity: 0.5;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .text p,
.appie-testimonial-slider .appie-testimonial-item .text p {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .text ul,
.appie-testimonial-slider .appie-testimonial-item .text ul {
    margin: 10px 0 0;
    padding: 0;
    list-style-type: none;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .text ul li,
.appie-testimonial-slider .appie-testimonial-item .text ul li {
    display: inline-block;
    margin: 0 2px;
}

.appie-testimonial-slider-rtl .appie-testimonial-item .text ul li i,
.appie-testimonial-slider .appie-testimonial-item .text ul li i {
    color: #ffb848;
}

.appie-testimonial-slider-rtl .slick-arrow,
.appie-testimonial-slider .slick-arrow {
    position: absolute;
    top: 62%;
    transform: translateY(-50%);
    font-size: 30px;
    color: #fff;
    z-index: 11;
    cursor: pointer;
    opacity: 0.5;
}

.appie-testimonial-slider-rtl .slick-arrow.prev,
.appie-testimonial-slider .slick-arrow.prev {
    left: -80px;
}

.appie-testimonial-slider-rtl .slick-arrow.next,
.appie-testimonial-slider .slick-arrow.next {
    right: -80px;
}

.appie-testimonial-slider-rtl .slick-dots,
.appie-testimonial-slider .slick-dots {
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translateX(-50%);
}

.appie-testimonial-slider-rtl .slick-dots li,
.appie-testimonial-slider .slick-dots li {
    display: inline-block;
}

.appie-testimonial-slider-rtl .slick-dots li button,
.appie-testimonial-slider .slick-dots li button {
    border: 0;
    background: #fff;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    font-size: 0;
    opacity: 0.5;
    margin: 0 4px;
}

.appie-testimonial-slider-rtl .slick-dots li.slick-active button,
.appie-testimonial-slider .slick-dots li.slick-active button {
    opacity: 1;
}

.appie-testimonial-2-box {
    background-image: url(../images/testimonial-bg-2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 140px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-testimonial-2-box {
        padding: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-testimonial-2-box {
        padding: 50px 30px;
    }
}

@media (max-width: 767px) {
    .appie-testimonial-2-box {
        padding: 50px 30px;
    }
}

.appie-testimonial-slider-2 .slick-arrow {
    position: absolute;
    right: 0;
    bottom: 0px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    border-radius: 50%;
    z-index: 9;
    cursor: pointer;
}

.appie-testimonial-slider-2 .slick-arrow:hover {
    background: #ff3e66;
    color: #fff;
    border-color: #ff3e66;
}

.appie-testimonial-slider-2 .slick-arrow.prev {
    right: 60px;
}

.appie-testimonial-slider-2 .slick-arrow.next {
    right: 0;
}

.appie-testimonial-slider-2-item .item {
    display: flex;
    padding-right: 30px;
    padding-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-testimonial-slider-2-item .item {
        display: block;
    }
}

@media (max-width: 767px) {
    .appie-testimonial-slider-2-item .item {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }
}

.appie-testimonial-slider-2-item .thumb {
    text-align: center;
    min-width: 140px;
    margin-right: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-testimonial-slider-2-item .thumb {
        margin-bottom: 30px;
        margin-right: 0;
    }
}

@media (max-width: 767px) {
    .appie-testimonial-slider-2-item .thumb {
        margin-bottom: 30px;
        margin-right: 0;
    }
}

.appie-testimonial-slider-2-item .thumb img {
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0px 20px 30px 0px rgba(0, 16, 49, 0.1);
    margin-bottom: 13px;
}

.appie-testimonial-slider-2-item .thumb ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.appie-testimonial-slider-2-item .thumb ul li {
    display: inline-block;
}

.appie-testimonial-slider-2-item .thumb ul li i {
    font-size: 14px;
    color: #ffb848;
}

.appie-testimonial-slider-2-item .thumb span {
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    opacity: 0.7;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-testimonial-slider-2-item .content {
        text-align: center;
    }
}

@media (max-width: 767px) {
    .appie-testimonial-slider-2-item .content {
        text-align: center;
    }
}

.appie-testimonial-slider-2-item .content p {
    color: #fff;
    font-size: 20px;
    line-height: 30px;
}

@media (max-width: 767px) {
    .appie-testimonial-slider-2-item .content p {
        font-size: 16px;
    }
}

.appie-testimonial-slider-2-item .content .author-info {
    margin-top: 27px;
}

.appie-testimonial-slider-2-item .content .author-info .title {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
}

.appie-testimonial-slider-2-item .content .author-info span {
    font-size: 14px;
    color: #fff;
    opacity: 0.7;
}

.appie-testimonial-about-area {
    background-image: url(../images/map-bg.png);
    background-size: auto;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.appie-testimonial-about-area .appie-section-title {
    margin-bottom: 30px;
}

.appie-testimonial-about-area .appie-section-title .appie-title {
    padding: 0 30px;
}

.testimonial-about-slider-active .testimonial-box {
    padding: 60px 100px 80px;
    box-shadow: 0px 40px 60px 0px rgba(14, 17, 51, 0.1);
    text-align: center;
    background: #fff;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 80px;
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .testimonial-about-slider-active .testimonial-box {
        padding: 60px 50px 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-about-slider-active .testimonial-box {
        margin-left: 50px;
        margin-right: 50px;
        padding: 30px 30px 50px;
    }
}

@media (max-width: 767px) {
    .testimonial-about-slider-active .testimonial-box {
        margin-left: 0px;
        margin-right: 0px;
        padding: 30px 30px 50px;
    }
}

.testimonial-about-slider-active .testimonial-box::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
}

.testimonial-about-slider-active .testimonial-box .icon i {
    font-size: 32px;
    color: #4985ff;
}

.testimonial-about-slider-active .testimonial-box .ratings-icon {
    padding-top: 25px;
    padding-bottom: 20px;
}

.testimonial-about-slider-active .testimonial-box .ratings-icon ul li {
    display: inline-block;
}

.testimonial-about-slider-active .testimonial-box .ratings-icon ul li i {
    color: #faa116;
    font-size: 14px;
}

.testimonial-about-slider-active .testimonial-box p {
    font-size: 20px;
    color: #0e1133;
    line-height: 30px;
}

@media (max-width: 767px) {
    .testimonial-about-slider-active .testimonial-box p {
        font-size: 16px;
        line-height: 26px;
    }
}

.testimonial-about-slider-active .slick-arrow {
    position: absolute;
    top: 43%;
    transform: translateY(-50%);
    z-index: 9;
    cursor: pointer;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 36px;
    border: 2px solid #db0f30;
    color: #db0f30;
    border-radius: 50%;
    transition: all linear 0.3s;
    background: #fff;
}

.testimonial-about-slider-active .slick-arrow:hover {
    background: #db0f30;
    color: #fff;
}

.testimonial-about-slider-active .slick-arrow.prev {
    left: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-about-slider-active .slick-arrow.prev {
        left: 0;
    }
}

@media (max-width: 767px) {
    .testimonial-about-slider-active .slick-arrow.prev {
        left: -10px;
    }
}

.testimonial-about-slider-active .slick-arrow.next {
    right: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-about-slider-active .slick-arrow.next {
        right: 0;
    }
}

@media (max-width: 767px) {
    .testimonial-about-slider-active .slick-arrow.next {
        right: -10px;
    }
}

.testimonial-box-about-slider-small-active {
    margin-top: -40px;
}

.testimonial-box-about-slider-small-active .item {
    padding-top: 20px;
    padding-bottom: 50px;
}

.testimonial-box-about-slider-small-active .item .thumb {
    max-width: 70px;
    text-align: center;
}

.testimonial-box-about-slider-small-active .item .thumb img {
    display: inline-block;
    width: 70px;
}

.testimonial-box-about-slider-small-active .item .content {
    display: none;
    text-align: center;
}

.testimonial-box-about-slider-small-active .item .content .title {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
}

.testimonial-box-about-slider-small-active .item .content span {
    font-size: 13px;
    font-weight: 500;
    color: #888890;
    white-space: nowrap;
}

.testimonial-box-about-slider-small-active .item.slick-center {
    margin-top: -20px;
}

/* =========================vue custom css======================== */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .testimonial-box-about-slider-small-active .slick-center .item {
        margin-top: -10px;
    }
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item .thumb {
    max-width: 70px;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item .thumb img {
    width: 70px;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item .content {
    display: inline-block;
    margin: 10px 0 0 -24px;
}

/* =========================vue custom css======================== */

/*===========================
    7.APPIE TEAM css 
===========================*/
.appie-team-item .thumb {
    position: relative;
    z-index: 10;
    overflow: hidden;
}

.appie-team-item .thumb img {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    width: 100%;
}

.appie-team-item .thumb::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.5;
    z-index: 9;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.appie-team-item .thumb ul {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    margin: 0;
    padding: 0;
    list-style-type: none;
    z-index: 11;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.appie-team-item .thumb ul li {
    display: inline-block;
    margin: 0 3px;
}

.appie-team-item .thumb ul li a {
    display: inline-block;
    height: 36px;
    width: 36px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    text-align: center;
    line-height: 36px;
    border-radius: 50px;
    color: #fff;
}

.appie-team-item .thumb ul li a:hover {
    background: #fff;
    color: #2b70fa;
    border-color: #fff;
}

.appie-team-item .content {
    padding-top: 27px;
}

.appie-team-item .content .title {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 8px;
}

.appie-team-item .content span {
    font-size: 14px;
    color: #81818d;
}

.appie-team-item:hover .thumb::before {
    opacity: 0.5;
}

.appie-team-item:hover .thumb ul {
    opacity: 1;
    visibility: visible;
}

.appie-team-item:hover .thumb img {
    transform: scale(1.1);
}

.appie-team-item.appie-team-item-about .thumb {
    border-radius: 6px;
    overflow: hidden;
}

.appie-team-item.appie-team-item-about .thumb::before {
    background-color: #2b70fa;
}

.appie-team-item.appie-team-item-about .thumb ul {
    left: 30px;
    transform: translateX(0);
}

.appie-team-item.appie-team-item-about .thumb ul li a {
    border-radius: 6px;
}

.appie-team-item.appie-team-item-about:hover .thumb::before {
    opacity: 0.9;
}

.appie-team-item.appie-team-item-about.appie-team-item-8 .thumb::before {
    background: #db0f30;
}

.appie-team-item.appie-team-item-about.appie-team-item-8 .thumb ul li a:hover {
    color: #db0f30;
}

.team-btn a {
    background: transparent;
    color: #0e1133;
    border: 2px solid #e7eaef;
}

.team-btn a i {
    padding-left: 6px;
}

.team-btn a:hover {
    background: #2b70fa;
    color: #fff;
}

.appie-team-about-area .team-btn a {
    border-color: #2b70fa;
    color: #2b70fa;
}

.appie-team-about-area .team-btn a:hover {
    color: #fff;
}

.appie-team-about-area.appie-team-8-area .team-btn a {
    border-color: #db0f30;
    color: #db0f30;
}

.appie-team-about-area.appie-team-8-area .team-btn a:hover {
    background: #db0f30;
    color: #fff;
}

/*===========================
    8.APPIE PRICING css 
===========================*/
.appie-pricing-area {
    background: #eef1f6;
}

.appie-pricing-area {
    position: relative;
    z-index: 10;
}

.appie-pricing-area .section-title {
    padding-bottom: 48px;
}

.appie-pricing-area .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    vertical-align: middle;
    margin: 0;
}

.appie-pricing-area .switch input {
    display: none;
}

.appie-pricing-area .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background: #fff;
}

.appie-pricing-area .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    background-color: #2b70fa;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.appie-pricing-area .switch.off .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
}

.appie-pricing-area .slider.round {
    border-radius: 34px;
}

.appie-pricing-area .slider.round:before {
    border-radius: 50%;
}

.appie-pricing-area ul.switch-toggler-list {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 40px;
}

.appie-pricing-area ul.switch-toggler-list li a {
    font-size: 14px;
    font-weight: 500;
    color: #505056;
    padding-left: 20px;
    padding-right: 20px;
    display: block;
}

.appie-pricing-area ul.switch-toggler-list li.active a {
    color: #0e1133;
}

.pricing-one__single {
    background: #fff;
    padding: 40px;
    border-radius: 6px;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pricing-one__single {
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .pricing-one__single {
        margin-bottom: 30px;
    }
}

.pricing-one__single .pricig-heading {
    border-bottom: 1px solid #e1e4ea;
    padding-bottom: 25px;
}

.pricing-one__single .pricig-heading h6 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 17px;
}

.pricing-one__single .pricig-heading .price-range {
    font-size: 24px;
    font-weight: 400;
    color: #0e1133;
}

.pricing-one__single .pricig-heading .price-range sup {
    font-size: 24px;
}

.pricing-one__single .pricig-heading .price-range span {
    font-size: 44px;
    font-weight: 900;
}

.pricing-one__single .pricig-heading .price-range p {
    display: inline-block;
    font-size: 14px;
}

.pricing-one__single .pricig-heading>p {
    font-size: 14px;
}

.pricing-one__single .pricig-body {
    padding-top: 25px;
}

.pricing-one__single .pricig-body ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.pricing-one__single .pricig-body ul li {
    font-size: 15px;
    font-weight: 500;
    color: #0e1133;
    margin-bottom: 10px;
}

.pricing-one__single .pricig-body ul li i {
    height: 26px;
    width: 26px;
    font-size: 14px;
    text-align: center;
    line-height: 26px;
    border-radius: 50%;
    background: #effaf3;
    color: #31c369;
}

.pricing-one__single .pricig-body .pricing-btn a {
    background: transparent;
    color: #0e1133;
    border: 2px solid #e7eaef;
}

.pricing-one__single .pricig-body .pricing-btn a:hover {
    background: #f8452d;
    color: #fff;
    border-color: #f8452d;
}

.pricing-one__single .pricing-rebon {
    position: absolute;
    right: 30px;
    top: -14px;
}

.pricing-one__single .pricing-rebon span {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background: #f8452d;
    line-height: 28px;
    padding: 0 18px;
    border-radius: 30px;
    box-shadow: 0px 10px 20px 0px rgba(183, 30, 10, 0.3);
}

.pricing-one__single.center {
    box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}

.pricing-one__single.center .pricig-body ul li i {
    color: #f8452d;
}

.pricing-one__single.center .pricig-body .pricing-btn a {
    background: #f8452d;
    border-color: #f8452d;
    color: #fff;
}

.pricing-one__single:hover {
    box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}

.pricing-one__single.pricing-one__single_2 {
    border: 1px solid #eef0f4;
}

.pricing-one__single.pricing-one__single_2 .pricig-body ul li i {
    background: #eef4fe;
    color: #3172f5;
}

.pricing-one__single.pricing-one__single_2 .pricig-body .pricing-btn a {
    border-radius: 30px;
}

.pricing-one__single.pricing-one__single_2 .pricig-body .pricing-btn a:hover {
    background: #ff3e66;
    border-color: #ff3e66;
}

.pricing-one__single.pricing-one__single_2 .pricig-body .pricing-rebon {
    right: 0;
    top: 20px;
}

.pricing-one__single.pricing-one__single_2 .pricig-body .pricing-rebon span {
    border-radius: 30px 0 0 30px;
    background: #2a6df5;
    box-shadow: none;
}

.pricing-one__single.pricing-one__single_2.active {
    border-top: 3px solid #ff3e66;
    box-shadow: 0px -3px 0px 0px rgba(255, 62, 102, 0.004),
        0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}

.pricing-one__single.pricing-one__single_2.active .pricig-body .pricing-btn a {
    background: #ff3e66;
    border-color: #ff3e66;
    color: #fff;
}

.pricing-one__single.pricing-one__single_2.item-2 .pricig-body ul li i {
    background: #effaf3;
    color: #31c369;
}

.pricing-one__single.pricing-one__single_2:hover {
    border-color: #fff;
    border-top: 3px solid #ff3e66;
    transform: translateY(-10px);
}

.appie-pricing-2-area .appie-section-title .nav {
    display: inline-block !important;
    border: 2px solid #e7eaef;
    padding: 3px;
    border-radius: 40px;
    margin-top: 55px;
}

.appie-pricing-2-area .appie-section-title .nav li {
    display: inline-block;
}

.appie-pricing-2-area .appie-section-title .nav li a {
    border-radius: 30px;
    font-weight: 500;
    font-size: 14px;
    color: #505056;
    padding: 5px 20px;
}

.appie-pricing-2-area .appie-section-title .nav li a.active {
    color: #fff;
    background: #ff3e66;
}

.appie-pricing-6-area .pricing-one__single .pricing-rebon span {
    background: #9b2cfa;
    box-shadow: none;
}

.appie-pricing-6-area .pricing-one__single.center .pricig-body .pricing-btn a {
    background: #9b2cfa;
    border-color: #9b2cfa;
}

/*===========================
    9.APPIE FAQ css 
===========================*/
.appie-faq-area {
    background: #eef1f6;
    position: relative;
    background-image: url(../images/shape/faq-shape.png);
    background-position: 94% 100%;
    background-size: auto;
    background-repeat: no-repeat;
}

.faq-accrodion {
    margin-top: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .faq-accrodion {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .faq-accrodion {
        margin-top: 30px;
    }
}

.faq-accrodion .accrodion {
    position: relative;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
}

.faq-accrodion .accrodion::before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0px;
    right: 0px;
    border-radius: 0px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    background-image: -webkit-gradient(linear,
            left top,
            right top,
            from(var(--thm-primary)),
            to(var(--thm-base)));
    background-image: linear-gradient(90deg,
            var(--thm-primary) 0%,
            var(--thm-base) 100%);
    -webkit-transition: -webkit-transform 0.4s ease;
    transition: -webkit-transform 0.4s ease;
    border-radius: 5px;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.faq-accrodion .accrodion+.accrodion {
    margin-top: 30px;
}

.faq-accrodion .accrodion.active {
    box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}

.faq-accrodion .accrodion.active .accrodion-inner {
    margin: 0 1px;
    background-color: transparent;
    border-color: #fff;
}

.faq-accrodion .accrodion.active::before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.faq-accrodion .accrodion.active .accrodion-title {
    padding-bottom: 0;
}

.faq-accrodion .accrodion.active .accrodion-title::before {
    opacity: 0;
}

.faq-accrodion .accrodion.active .accrodion-title::after {
    opacity: 1;
    color: #fff;
}

.faq-accrodion .accrodion .accrodion-inner {
    background-color: #fff;
    padding: 18px 0;
    border-style: solid;
    border-width: 1px;
    border-color: #f2f4f8;
    border-radius: 0px;
    position: relative;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    overflow: hidden;
}

.faq-accrodion .accrodion .accrodion-title {
    padding: 0px 38px;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

@media (max-width: 767px) {
    .faq-accrodion .accrodion .accrodion-title {
        padding: 0 38px 0 20px;
    }
}

.faq-accrodion .accrodion .accrodion-title::before {
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    font-size: 14px;
    color: #2b70fa;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    position: absolute;
    top: 50%;
    right: 30px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    height: 40px;
    width: 40px;
    background: #eef4fe;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .faq-accrodion .accrodion .accrodion-title::before {
        right: 5px;
    }
}

@media (max-width: 767px) {
    .faq-accrodion .accrodion .accrodion-title::before {
        right: 20px;
    }
}

.faq-accrodion .accrodion .accrodion-title::after {
    content: "\f106";
    font-weight: 400;
    font-family: "Font Awesome 5 Pro";
    font-size: 14px;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    position: absolute;
    top: 50%;
    right: 30px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    background: #2b70fa;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .faq-accrodion .accrodion .accrodion-title::after {
        right: 5px;
    }
}

@media (max-width: 767px) {
    .faq-accrodion .accrodion .accrodion-title::after {
        right: 20px;
    }
}

.faq-accrodion .accrodion .accrodion-title h4 {
    margin: 0;
    color: #0e1133;
    font-size: 18px;
    font-weight: 700;
}

.faq-accrodion .accrodion .accrodion-content .inner {
    padding: 0 38px;
    padding-right: 35px;
    padding-top: 22px;
    padding-bottom: 0px;
}

@media (max-width: 767px) {
    .faq-accrodion .accrodion .accrodion-content .inner {
        padding: 0 38px 0 20px;
    }
}

.faq-accrodion .accrodion .accrodion-content p {
    margin: 0;
    color: #505056;
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
}

.appie-faq-8-area {
    background: #eef1f6;
    position: relative;
}

.appie-faq-8-area .faq-accordion .accrodion .accrodion-inner .accrodion-title::before {
    background: #fcecef;
    color: #db0f30;
}

.appie-faq-8-area .faq-accordion .accrodion .accrodion-inner .accrodion-title::after {
    background: #db0f30;
    color: #fff;
}

.appie-faq-8-area .faq-play-box {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background-image: url(../images/faq-play-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-faq-8-area .faq-play-box {
        display: none;
    }
}

@media (max-width: 767px) {
    .appie-faq-8-area .faq-play-box {
        display: none;
    }
}

.appie-faq-8-area .faq-play-box .play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.appie-faq-8-area .faq-play-box .play-btn a {
    height: 80px;
    width: 80px;
    text-align: center;
    line-height: 80px;
    background: #fff;
    border-radius: 50%;
    color: #2b70fa;
    position: relative;
}

.appie-faq-8-area .faq-play-box .play-btn a::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 50%;
    animation: linear infinite popup 1.5s;
}

.appie-faq-8-area .faq-play-box .faq-play-counter {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
}

.appie-faq-8-area .faq-play-box .faq-play-counter .box-1 {
    background: rgba(42, 109, 245, 0.9);
    padding: 50px 60px;
}

.appie-faq-8-area .faq-play-box .faq-play-counter .box-1 .title {
    font-size: 30px;
    color: #fff;
}

.appie-faq-8-area .faq-play-box .faq-play-counter .box-1 span {
    font-size: 14px;
    color: #d1dbfb;
}

.appie-faq-8-area .faq-play-box .faq-play-counter .box-1.box-2 {
    background: rgba(14, 17, 51, 0.9);
}

/*===========================
    10.APPIE BLOG css 
===========================*/
.appie-blog-item .thumb {
    overflow: hidden;
    border-radius: 6px;
}

.appie-blog-item .thumb img {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    width: 100%;
}

.appie-blog-item .content {
    padding-top: 20px;
}

.appie-blog-item .content .blog-meta ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.appie-blog-item .content .blog-meta ul li {
    display: inline-block;
    font-size: 14px;
    padding-right: 22px;
    position: relative;
}

.appie-blog-item .content .blog-meta ul li a {
    font-weight: 400;
    color: #505056;
}

.appie-blog-item .content .blog-meta ul li:nth-child(1)::before {
    position: absolute;
    content: "";
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    height: 5px;
    width: 5px;
    background: #2b70fa;
    border-radius: 50%;
}

.appie-blog-item .content .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    margin-top: 2px;
}

.appie-blog-item .content .title a {
    color: #0e1133;
}

.appie-blog-item .content .title a:hover {
    color: #2b70fa;
}

.appie-blog-item .content>a {
    font-size: 15px;
    font-weight: 500;
    color: #0e1133;
    margin-top: 14px;
}

.appie-blog-item .content>a i {
    padding-left: 6px;
}

.appie-blog-item .content>a:hover {
    color: #2b70fa;
}

.appie-blog-item:hover .thumb img {
    transform: scale(1.1);
}

.appie-blog-3-area .blog-btn a {
    background: transparent;
    color: #0e1133;
    border: 2px solid #e7eaef;
}

.appie-blog-3-area .blog-btn a:hover {
    background: #ff6b58;
    border-color: #ff6b58;
    color: #fff;
}

.appie-blog-3-area.appie-blog-6-area .blog-btn a:hover {
    background: #9b2cfa;
    border-color: #9b2cfa;
}

.appie-blog-3-area.appie-blog-8-area {
    background: #eef1f6;
    position: relative;
    z-index: 10;
}

.appie-blog-3-area.appie-blog-8-area .appie-blog-8-shape-1 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.appie-blog-3-area.appie-blog-8-area .appie-blog-8-shape-2 {
    position: absolute;
    right: 0;
    bottom: 340px;
    z-index: -1;
}

.appie-blog-3-area.appie-blog-8-area .blog-btn a {
    border-color: #db0f30;
    color: #db0f30;
}

.appie-blog-3-area.appie-blog-8-area .blog-btn a:hover {
    background: #db0f30;
    color: #fff;
}

.appie-blog-item-3 {
    box-shadow: 0px 15px 50px 0px rgba(14, 17, 51, 0.1);
    border-radius: 10px;
    padding: 35px;
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-blog-item-3 {
        padding: 15px;
    }
}

@media (max-width: 767px) {
    .appie-blog-item-3 {
        padding: 15px;
        display: block;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-blog-item-3 {
        display: flex;
    }
}

.appie-blog-item-3 .thumb {
    max-width: 120px;
    min-width: 120px;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 30px;
}

@media (max-width: 767px) {
    .appie-blog-item-3 .thumb {
        max-width: 100%;
        min-width: 100%;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-blog-item-3 .thumb {
        max-width: 120px;
        min-width: 120px;
    }
}

.appie-blog-item-3 .thumb img {
    width: 100%;
    transition: all linear 0.3s;
}

.appie-blog-item-3 .content {
    padding-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-blog-item-3 .content {
        padding-right: 0;
    }
}

@media (max-width: 767px) {
    .appie-blog-item-3 .content {
        padding-right: 0;
        margin-top: 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-blog-item-3 .content {
        padding-right: 50px;
        margin-top: 0;
    }
}

.appie-blog-item-3 .content .title {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-blog-item-3 .content .title {
        font-size: 18px;
        line-height: 28px;
    }
}

.appie-blog-item-3 .content .title a {
    color: #0e1133;
}

.appie-blog-item-3 .content .title a:hover {
    color: #ff6b58;
}

.appie-blog-item-3 .content ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.appie-blog-item-3 .content ul li {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #505056;
    margin-right: 22px;
}

.appie-blog-item-3 .content ul li i {
    padding-right: 6px;
}

.appie-blog-item-3 .content ul li a {
    color: #505056;
    font-size: 14px;
    font-weight: 400;
}

.appie-blog-item-3:hover .thumb img {
    transform: scale(1.1);
}

.appie-blog-item-3.appie-blog-item-6 .content .title a:hover {
    color: #9b2cfa;
}

.appie-blog-item-3.appie-blog-item-8 {
    background: #fff;
}

.appie-blog-item-3.appie-blog-item-8 .content .title a:hover {
    color: #db0f30;
}

/*--------------------------------------------------------
/ 7. Blog Page
/---------------------------------------------------------*/
.blogpage-section {
    position: relative;
    padding: 100px 0;
}

.bisylms-pagination {
    position: relative;
    z-index: 3;
    margin: 10px 0 0;
}

.bisylms-pagination a,
.bisylms-pagination span {
    font-size: 14px;
    color: #2b70fa;
    font-weight: 600;
    line-height: 40px;
    background: rgba(88, 56, 252, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 3px;
    text-align: center;
    display: inline-block;
    padding: 0 0;
    margin: 0 5px 0 0;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.bisylms-pagination a:hover,
.bisylms-pagination .current {
    color: #fff;
    background: #2b70fa;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
    -moz-box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
    box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
}

.bisylms-pagination a.next,
.bisylms-pagination a.prev {
    width: auto;
    padding: 0 15px;
    text-transform: capitalize;
}

.bisylms-pagination a.next i {
    font-size: 18px;
    position: relative;
    top: 4px;
    margin-left: 9px;
}

.bisylms-pagination a.prev i {
    font-size: 18px;
    position: relative;
    top: 4px;
    margin-right: 9px;
}

/*--- Sidebar ---*/
.blog-sidebar {
    position: relative;
    margin-left: 30px;
}

.blog-sidebar .widget {
    position: relative;
    border: 1px solid #ebecf1;
    border-radius: 10px;
    margin: 0 0 30px;
    padding: 32px 30px 37px;
}

.blog-sidebar .widget .widget-title {
    color: #2c234d;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
    border-bottom: 1px solid #cfd2de;
    display: inline-block;
    position: relative;
    padding-bottom: 15px;
    margin: 0 0 17px;
}

.blog-sidebar .widget.widget-search {
    padding: 0;
    border: none;
    border-radius: 0;
    background: transparent;
}

.blog-sidebar .widget.widget-search .widget-title {
    display: none;
}

.search-form {
    position: relative;
}

.search-form input[type="search"] {
    width: 100%;
    height: 50px;
    font-size: 14px;
    line-height: 50px;
    border-radius: 3px;
    font-weight: 500;
    border: none;
    background: #f1f0f5;
    color: #8f8d98;
    outline: none;
    padding: 0 25px;
}

.search-form input[type="search"]::-moz-placeholder {
    color: #8f8d98;
    opacity: 1;
}

.search-form input[type="search"]::-ms-input-placeholder {
    color: #8f8d98;
    opacity: 1;
}

.search-form input[type="search"]::-webkit-input-placeholder {
    color: #8f8d98;
    opacity: 1;
}

.search-form button {
    font-size: 16px;
    line-height: 52px;
    color: #2c234d;
    background: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 17px;
    top: 0;
    z-index: 2;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.search-form button:hover {
    color: #2b70fa;
}

.blog-sidebar .widget ul {
    margin: 0;
    padding: 0;
}

.blog-sidebar .widget ul li {
    list-style: none;
    display: block;
    font-size: 14px;
    line-height: 40px;
    font-weight: 600;
    color: #5d5a67;
    position: relative;
    padding-left: 25px;
}

.blog-sidebar .widget ul li:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "\f105";
    font-family: "Font Awesome 5 Pro";
    font-size: 20px;
    line-height: 40px;
    font-weight: 300;
    color: #b2b0bc;
}

.blog-sidebar .widget ul li a {
    color: inherit;
    display: inline-block;
}

.blog-sidebar .widget ul li a:hover {
    color: #2b70fa;
}

.blog-sidebar .widget ul li span {
    color: #b2b0bc;
    margin-left: 9px;
}

.popular-post {
    position: relative;
    min-height: 70px;
    padding-left: 90px;
    margin: 9px 0 24px;
}

.popular-post:last-child {
    margin-bottom: 0;
}

.popular-post img {
    width: 70px;
    height: 70px;
    position: absolute;
    border-radius: 3px;
    left: 0;
    top: 4px;
}

.popular-post h5 {
    font-size: 15px;
    line-height: 20px;
    color: #2c234d;
    margin: 0 0 12px;
    font-weight: 700;
}

.popular-post h5 a {
    color: inherit;
}

.popular-post h5 a:hover {
    color: #2b70fa;
}

.popular-post span {
    display: block;
    font-size: 13px;
    line-height: 0.8;
    color: #7e7c87;
    font-weight: 600;
}

.tags {
    position: relative;
}

.tags a {
    display: inline-block;
    height: 34px;
    background: #f8f8f8;
    border-radius: 3px;
    text-align: center;
    font-size: 13px;
    color: #797781;
    line-height: 34px;
    padding: 0 15px;
    font-weight: 600;
    margin: 10px 6px 0 0;
}

.tags a:hover {
    background: #2b70fa;
    color: #fff;
}

/*--------------------------------------------------------
/ 8. Single Blog Page
/---------------------------------------------------------*/
.single-p-banner {
    padding-top: 158px;
    background-position: top center;
}

.post-cate {
    position: relative;
    z-index: 2;
    display: inline-block;
    color: #fff;
    line-height: 0.8;
    text-transform: uppercase;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 0 20px;
}

.post-cate:hover {
    color: #fff;
}

.single-p-banner .banner-title {
    line-height: 60px;
    margin-bottom: 16px;
}

.single-p-banner .bp-meta {
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0;
}

.single-p-banner .bp-meta a i,
.single-p-banner .bp-meta a {
    color: #fff;
}

.single-post-area {
    position: relative;
}

.single-post-area p {
    line-height: 30px;
    margin: 0 0 30px;
}

.post-thumb {
    position: relative;
    margin: 42px 0 35px;
}

.post-thumb img {
    width: auto;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.article-title {
    font-size: 30px;
    line-height: 34px;
    color: #2c234d;
    margin: 0 0 15px;
}

blockquote {
    position: relative;
    margin: 41px 0;
    padding: 67px 50px 40px;
    background: #f8f8f8;
    border-radius: 10px;
    -webkit-box-shadow: -4px 0px 0px 0px #5838fc;
    -moz-box-shadow: -4px 0px 0px 0px #5838fc;
    box-shadow: -4px 0px 0px 0px #5838fc;
}

blockquote:before {
    position: absolute;
    left: 50px;
    top: 68px;
    content: ",,";
    font-family: "Open Sans", sans-serif;
    font-size: 100px;
    line-height: 25px;
    color: #2b70fa;
    letter-spacing: -5px;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

blockquote:after {
    position: absolute;
    right: 44px;
    bottom: 122px;
    content: ",,";
    font-family: "Open Sans", sans-serif;
    font-size: 200px;
    line-height: 25px;
    color: #f0f0f0;
    letter-spacing: -10px;
}

blockquote p {
    font-size: 20px;
    line-height: 32px;
    color: #2c234d;
    font-weight: 600;
    margin: 0 0 18px;
}

blockquote cite {
    display: block;
    font-size: 14px;
    line-height: 0.8;
    color: #797781;
    font-style: normal;
}

.single-post-area blockquote p {
    line-height: 32px;
    margin: 0 0 18px;
}

.post-tags {
    position: relative;
    display: flex;
    justify-content: flex-start;
}

.post-tags h5 {
    font-size: 14px;
    color: #2c234d;
    font-weight: 600;
    margin: 7px 20px 0 0;
}

.post-tags a {
    display: inline-block;
    height: 30px;
    background: #f8f8f8;
    border-radius: 3px;
    text-align: center;
    font-size: 13px;
    color: #797781;
    line-height: 30px;
    padding: 0 18px;
    font-weight: 600;
    margin: 0 10px 0 0;
}

.post-tags a:hover {
    background: #2b70fa;
    color: #fff;
}

.post-share {
    position: relative;
    border-top: 1px solid #ebecf2;
    padding: 30px 0 0;
    margin: 20px 0 0;
    display: flex;
    justify-content: flex-start;
}

.post-share h5 {
    font-size: 14px;
    color: #2c234d;
    font-weight: 600;
    margin: 13px 20px 0 0;
}

.post-share a {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #446bb9;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    font-size: 14px;
    margin-right: 6px;
}

.post-share a.fac {
    background: #446bb9;
}

.post-share a.twi {
    background: #00aced;
}

.post-share a.goo {
    background: #ea4335;
}

.post-share a:hover {
    -webkit-box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
    -moz-box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
    box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
}

.post-admin {
    position: relative;
    margin: 84px 0;
    padding: 44px 40px 55px 150px;
    background: transparent;
    border-radius: 10px;
    -webkit-box-shadow: -4px 0px 0px 0px #5838fc;
    -moz-box-shadow: -4px 0px 0px 0px #5838fc;
    box-shadow: -4px 0px 0px 0px #5838fc;
}

.post-admin img {
    position: absolute;
    left: 40px;
    top: 40px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.post-admin a {
    display: inline-block;
    font-size: 18px;
    line-height: 22px;
    color: #2c234d;
    font-weight: 700;
    margin: 0 0 7px;
}

.post-admin span {
    display: block;
    font-size: 14px;
    color: #797781;
    font-weight: 400;
    line-height: 0.8;
    margin-bottom: 27px;
}

.post-admin p {
    font-size: 16px;
    line-height: 26px;
    color: #5d5a67;
    margin: 0;
}

.comment-area {
    position: relative;
    margin: 80px 0 66px;
}

.comment-area h3 {
    font-size: 20px;
    line-height: 0.8;
    color: #2c234d;
    margin: 0 0 30px;
}

.comment-list {
    margin: 0;
    padding: 0;
}

.comment-list li {
    list-style: none;
    display: block;
    margin: 0 0 0;
}

.comment-list .children {
    margin: 0 0 0;
    padding-left: 100px;
}

.single-comment {
    position: relative;
    padding-left: 100px;
    padding-top: 7px;
    padding-bottom: 30px;
}

.c-border {
    border-bottom: 1px solid #ebecf2;
    margin-top: 24px;
}

.single-comment img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
}

.children .single-comment img {
    width: 50px;
    height: 50px;
}

.children .single-comment {
    padding-left: 80px;
}

.single-comment h5 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 7px;
}

.single-comment h5 a {
    color: inherit;
}

.single-comment h5 a:hover {
    color: #2b70fa;
}

.single-comment span {
    display: block;
    font-size: 13px;
    line-height: 0.8;
    font-weight: 600;
    color: #797781;
    margin: 0 0 24px;
}

.comment {
    position: relative;
    margin: 0 0 14px;
}

.comment p {
    font-size: 14px;
    line-height: 24px;
    color: #686875;
    margin: 0;
}

.single-comment .comment-reply-link {
    display: inline-block;
    font-size: 12px;
    line-height: 0.8;
    color: #2b70fa;
    font-weight: 600;
}

.single-comment .comment-reply-link i {
    font-size: 16px;
    margin-right: 11px;
    font-weight: 600;
    position: relative;
    top: 2px;
}

.single-comment .comment-reply-link:hover {
    color: #2c234d;
}

.comment-form-area {
    position: relative;
}

.comment-form-area h3 {
    color: #2c234d;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 9px;
}

.comment-form-area p {
    font-size: 14px;
    line-height: 24px;
    color: #686875;
    margin: 0 0 43px;
}

.comment-form {
    position: relative;
}

.comment-form input[type="text"],
.comment-form input[type="number"],
.comment-form input[type="url"],
.comment-form textarea,
.comment-form input[type="email"] {
    width: 100%;
    height: 56px;
    font-size: 14px;
    line-height: 56px;
    font-weight: 400;
    border-radius: 3px;
    border: none;
    background: #f5f5f7;
    color: #7e7c87;
    outline: none;
    border: 1px solid transparent;
    padding: 0 20px;
    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.comment-form textarea {
    height: 180px;
    resize: none;
    line-height: 30px;
    padding-top: 14px;
}

.comment-form textarea::-moz-placeholder,
.comment-form input[type="text"]::-moz-placeholder,
.comment-form input[type="url"]::-moz-placeholder,
.comment-form input[type="number"]::-moz-placeholder,
.comment-form input[type="email"]::-moz-placeholder {
    color: #7e7c87;
    opacity: 1;
}

.comment-form textarea::-ms-input-placeholder,
.comment-form input[type="text"]::-ms-input-placeholder,
.comment-form input[type="url"]::-ms-input-placeholder,
.comment-form input[type="number"]::-ms-input-placeholder,
.comment-form input[type="email"]::-ms-input-placeholder {
    color: #7e7c87;
    opacity: 1;
}

.comment-form textarea::-webkit-input-placeholder,
.comment-form input[type="text"]::-webkit-input-placeholder,
.comment-form input[type="url"]::-webkit-input-placeholder,
.comment-form input[type="number"]::-webkit-input-placeholder,
.comment-form input[type="email"]::-webkit-input-placeholder {
    color: #7e7c87;
    opacity: 1;
}

.comment-form textarea:focus,
.comment-form input[type="url"]:focus,
.comment-form input[type="text"]:focus,
.comment-form input[type="number"]:focus,
.comment-form input[type="email"]:focus {
    background: transparent;
    border-color: #ece9f9;
    -webkit-box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
    -moz-box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
    box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
}

.comment-form button,
.comment-form input[type="submit"] {
    height: 50px;
    border-radius: 3px;
    background: #00d1b3;
    color: #fff;
    text-align: center;
    border: none;
    outline: none;
    padding: 0 36px;
    font-size: 13px;
    line-height: 50px;
    font-weight: 600;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.comment-form button:hover,
.contact-form input[type="submit"]:hover {
    -webkit-box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
    -moz-box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
    box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
}

.save-comment {
    position: relative;
    margin: 5px 0 25px;
}

.save-comment input[type="checkbox"] {
    display: none;
}

.save-comment label {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
    font-weight: 400;
    padding-left: 31px;
    position: relative;
    cursor: pointer;
    color: #686875;
    margin: 0 0 0;
}

.save-comment label:before {
    background: transparent;
    content: "";
    height: 16px;
    left: 0;
    position: absolute;
    border-radius: 3px;
    top: 3px;
    width: 16px;
    border: 2px solid #ababb1;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.save-comment input:checked+label::after {
    background: transparent;
    bottom: 0;
    font-family: "ElegantIcons";
    content: "\4e";
    color: #2b70fa;
    font-size: 10px;
    left: 2px;
    position: absolute;
    top: -1px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.post-item-1 {
    position: relative;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 30px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(21, 14, 56, 0.08);
    -moz-box-shadow: 0px 2px 4px 0px rgba(21, 14, 56, 0.08);
    box-shadow: 0px 2px 4px 0px rgba(21, 14, 56, 0.08);
}

.post-item-1 img {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0 0;
}

.b-post-details {
    position: relative;
    padding: 30px 35px 37px;
}

.bp-meta {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 15px;
}

.bp-meta a {
    display: inline-block;
    font-size: 14px;
    line-height: 0.8;
    color: #505056;
    font-weight: 600;
    margin-right: 30px;
}

.bp-meta a i {
    color: #2b70fa;
    margin-right: 9px;
    position: relative;
    top: 1px;
}

.bp-meta a:last-child {
    margin-right: 0;
}

.bp-meta a:hover {
    color: #2b70fa;
}

.b-post-details h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
    padding-top: 10px;
    font-weight: 700;
}

.b-post-details h3 a {
    color: inherit;
}

.b-post-details h3 a:hover {
    color: #2b70fa;
}

.post-item-1:hover {
    -webkit-box-shadow: 0px 30px 50px 0px rgba(11, 2, 55, 0.1);
    -moz-box-shadow: 0px 30px 50px 0px rgba(11, 2, 55, 0.1);
    box-shadow: 0px 30px 50px 0px rgba(11, 2, 55, 0.1);
}

.b-post-details a.read-more {
    font-size: 14px;
    font-weight: 700;
    color: #505056;
}

.b-post-details a.read-more i {
    padding-left: 8px;
}

.b-post-details a.read-more:hover {
    color: #2b70fa;
}

/*===========================
    10.APPIE PROJECT css 
===========================*/
.appie-project-area .appie-project-box {
    background-image: url(../images/project-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 70px;
    padding-top: 75px;
    padding-bottom: 85px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

@media (max-width: 767px) {
    .appie-project-area .appie-project-box {
        padding-left: 30px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-project-area .appie-project-box {
        padding-left: 70px;
    }
}

.appie-project-area .appie-project-box .appie-project-thumb {
    position: absolute;
    right: 70px;
    bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-project-area .appie-project-box .appie-project-thumb {
        right: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-project-area .appie-project-box .appie-project-thumb {
        display: none;
    }
}

@media (max-width: 767px) {
    .appie-project-area .appie-project-box .appie-project-thumb {
        display: none;
    }
}

.appie-project-content {
    padding-right: 50px;
}

.appie-project-content .title {
    color: #fff;
    font-size: 50px;
    line-height: 60px;
    padding-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-project-content .title {
        font-size: 36px;
        line-height: 46px;
    }
}

@media (max-width: 767px) {
    .appie-project-content .title {
        font-size: 30px;
        line-height: 40px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-project-content .title {
        font-size: 36px;
        line-height: 46px;
    }
}

.appie-project-content p {
    color: #fff;
    opacity: 0.8;
}

.appie-project-content .input-box {
    display: flex;
}

@media (max-width: 767px) {
    .appie-project-content .input-box {
        display: block;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-project-content .input-box {
        display: flex;
    }
}

.appie-project-content .input-box input {
    width: 100%;
    height: 50px;
    padding-left: 30px;
    border-radius: 6px;
    border: 0;
}

.appie-project-content .input-box button {
    margin-left: 10px;
    padding: 0 30px;
    border-radius: 6px;
    padding: 0 25px;
    background: #0e1133;
    border: 0;
    color: #fff;
    line-height: 50px;
}

@media (max-width: 767px) {
    .appie-project-content .input-box button {
        margin-top: 20px;
        margin-left: 0;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-project-content .input-box button {
        margin-top: 0px;
        margin-left: 10px;
    }
}

.appie-project-3-area.appie-project-5-area {
    background: #eef1f6;
}

.appie-project-3-box {
    background-image: url(../images/project-bg-2.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    padding: 55px 70px;
    box-shadow: 0px 40px 50px 0px rgba(138, 16, 0, 0.3);
    margin-bottom: -110px;
    position: relative;
    overflow: hidden;
    z-index: 10;
}

@media (max-width: 767px) {
    .appie-project-3-box {
        text-align: center;
    }
}

.appie-project-3-box .title {
    color: #fff;
    font-size: 46px;
    line-height: 56px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-project-3-box .title {
        font-size: 36px;
        line-height: 46px;
    }
}

@media (max-width: 767px) {
    .appie-project-3-box .title {
        font-size: 35px;
        line-height: 46px;
        margin-bottom: 30px;
    }
}

.appie-project-3-box a {
    background: #fff;
    color: #0e1133;
    border-color: #fff;
    line-height: 55px;
    padding: 0 40px;
}

/*===========================
    10.APPIE FOOTER css 
===========================*/
.appie-footer-area {
    background: #1c435d;
    padding-top: 10px;
    padding-bottom: 40px;
}

.appie-footer-area.appie-footer-3-area {
    padding-top: 180px;
}

.appie-footer-area.appie-footer-3-area.appie-footer-5-area {
    background: #fff;
}

.appie-footer-area.appie-footer-about-area {
    padding-top: 230px;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area {
    background: #fff;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area .footer-about-widget .social ul li a {
    border: 2px solid #e6e7ea;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area .footer-about-widget .social ul li a:hover {
    background: #db0f30;
    border-color: #db0f30;
    color: #fff;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area .footer-navigation ul li a:hover {
    color: #db0f30;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area .footer-widget-info ul li a i {
    color: #db0f30;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area .footer-copyright .apps-download-btn ul li a {
    background: #0e1133;
    color: #fff;
    border-color: #0e1133;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area .footer-copyright .apps-download-btn ul li a:hover {
    background: #fff;
    color: #0e1133;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area .footer-copyright .apps-download-btn ul li a.item-2 {
    background: #fff;
    color: #0e1133;
}

.appie-footer-area.appie-footer-about-area.appie-footer-8-area .footer-copyright .apps-download-btn ul li a.item-2:hover {
    background: #0e1133;
    color: #fff;
    border-color: #0e1133;
}

.footer-about-widget {
    padding-right: 70px;
    margin-top: 30px;
}

@media (max-width: 767px) {
    .footer-about-widget {
        text-align: center;
        padding-right: 0;
    }
}

.footer-about-widget p {
    padding-top: 25px;
}

.footer-about-widget>a {
    font-size: 15px;
    font-weight: 500;
    color: #0e1133;
    margin-top: 15px;
}

.footer-about-widget>a i {
    padding-left: 6px;
}

.footer-about-widget .social ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.footer-about-widget .social ul li {
    display: inline-block;
}

.footer-about-widget .social ul li a {
    height: 36px;
    width: 36px;
    text-align: center;
    background: #fff;
    color: #0e1133;
    border-radius: 6px;
    line-height: 36px;
    margin-right: 5px;
}

.footer-about-widget .social ul li a:hover {
    background: #2b70fa;
    color: #fff;
}

.footer-about-widget.footer-about-widget-2 ul li a:hover {
    background: #ff3e66;
}

.footer-about-widget.footer-about-widget-3 ul li a:hover {
    background: #ff6b58;
}

.footer-about-widget.footer-about-widget-6 ul li a:hover {
    background: #9b2cfa;
}

.footer-about-widget.footer-about-widget-5 ul li a:hover {
    background: #801f82;
}

.footer-navigation {
    margin-top: 30px;
}

@media (max-width: 767px) {
    .footer-navigation {
        text-align: center;
    }
}

.footer-navigation .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 18px;
}

.footer-navigation ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.footer-navigation ul li a {
    color: #505056;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 6px;
}

.footer-navigation ul li a:hover {
    color: #2b70fa;
}

.footer-navigation.footer-navigation-2 ul li a:hover {
    color: #ff3e66;
}

.footer-navigation.footer-navigation-3 ul li a:hover {
    color: #ff6b58;
}

.footer-navigation.footer-navigation-6 ul li a:hover {
    color: #9b2cfa;
}

.footer-navigation.footer-navigation-5 ul li a:hover {
    color: #801f82;
}

.footer-widget-info {
    margin-top: 30px;
}

@media (max-width: 767px) {
    .footer-widget-info {
        text-align: center;
    }
}

.footer-widget-info .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 18px;
}

.footer-widget-info ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.footer-widget-info ul li a {
    color: #505056;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    padding-left: 30px;
    position: relative;
}

.footer-widget-info ul li a i {
    position: absolute;
    left: 0;
    top: 0;
    color: #2b70fa;
}

.footer-copyright {
    padding-top: 35px;
    border-top: 1px solid #e1e5ec;
    margin-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-copyright {
        display: block !important;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .footer-copyright {
        display: block !important;
        text-align: center;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-copyright .apps-download-btn {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .footer-copyright .apps-download-btn {
        margin-bottom: 20px;
    }
}

.footer-copyright .apps-download-btn ul {
    padding: 0;
    list-style-type: none;
}

.footer-copyright .apps-download-btn ul li {
    display: inline-block;
    margin-right: 8px;
}

@media (max-width: 767px) {
    .footer-copyright .apps-download-btn ul li {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer-copyright .apps-download-btn ul li {
        margin-bottom: 0px;
    }
}

.footer-copyright .apps-download-btn ul li a {
    background: #fff;
    color: #0e1133;
    line-height: 40px;
    padding: 0 23px;
    border-radius: 6px;
    border: 2px solid #fff;
}

.footer-copyright .apps-download-btn ul li a i {
    padding-right: 6px;
}

.footer-copyright .apps-download-btn ul li a:hover {
    background: transparent;
    color: #0e1133;
    border-color: #e7eaef;
}

.footer-copyright .apps-download-btn ul li a.item-2 {
    background: transparent;
    color: #0e1133;
    border-color: #e7eaef;
}

.footer-copyright .apps-download-btn ul li a.item-2:hover {
    background: #fff;
    color: #0e1133;
}

.appie-error-area {
    padding-top: 250px;
}

.appie-error-content span {
    display: block;
    font-size: 50px;
    color: #db0f30;
    font-weight: 800;
    padding-top: 40px;
}

.appie-error-content .title {
    font-size: 50px;
    font-weight: 800;
    color: #0e1133;
    padding-bottom: 11px;
}

.appie-error-content p {
    line-height: 26px;
    color: #505056;
    font-size: 16px;
    padding: 0 50px;
}

.appie-error-content a {
    border: 2px solid #2b70fa;
    line-height: 45px;
    padding: 0 35px;
    border-radius: 6px;
    margin-top: 30px;
}

.appie-signup-area.appie-signup-8-area {
    background: #eef1f6;
}

.appie-signup-area.appie-signup-8-area .appie-signup-box form .input-box button {
    background: #db0f30;
}

.appie-signup-box {
    background-image: url(../images/signup-bg.jpg);
    padding: 63px 100px 60px;
    border-radius: 10px;
    box-shadow: 0px 40px 50px 0px rgba(0, 24, 72, 0.3);
    overflow: hidden;
    position: relative;
    margin-bottom: -160px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-signup-box {
        padding: 30px 50px;
    }
}

@media (max-width: 767px) {
    .appie-signup-box {
        padding: 30px;
    }
}

.appie-signup-box .thumb {
    position: absolute;
    right: 70px;
    bottom: 30px;
    width: 160px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-signup-box .thumb {
        display: none;
    }
}

@media (max-width: 767px) {
    .appie-signup-box .thumb {
        display: none;
    }
}

.appie-signup-box span {
    color: #fff;
    font-weight: 500;
}

.appie-signup-box .title {
    font-size: 46px;
    color: #fff;
    padding-bottom: 20px;
}

@media (max-width: 767px) {
    .appie-signup-box .title {
        font-size: 30px;
    }
}

.appie-signup-box form {
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-signup-box form {
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .appie-signup-box form {
        flex-direction: column;
    }
}

.appie-signup-box form .input-box {
    margin-right: 10px;
    margin-top: 10px;
}

.appie-signup-box form .input-box input {
    width: 300px;
    background: #fff;
    line-height: 55px;
    border-radius: 6px;
    padding-left: 30px;
    border: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-signup-box form .input-box input {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .appie-signup-box form .input-box input {
        width: 100%;
    }
}

.appie-signup-box form .input-box button {
    background: #2b70fa;
    line-height: 55px;
    border: 0;
    color: #fff;
    border-radius: 6px;
    padding: 0 45px;
    font-weight: 500;
}

.appie_checkbox_common {
    margin-top: 15px;
}

.appie_checkbox_common label {
    color: #fff;
    font-size: 14px;
    margin-bottom: 12px;
    cursor: pointer;
    font-weight: 400;
}

.appie_checkbox_common label a {
    text-decoration: underline;
    color: #b3b6bc;
}

.appie_checkbox_common input[type="checkbox"] {
    display: none;
}

.appie_checkbox_common input[type="checkbox"]+label span {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: -1px 15px 0 0;
    vertical-align: middle;
    cursor: pointer;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #fff;
    position: relative;
}

.appie_checkbox_common input[type="checkbox"]+label span:before {
    color: #fff;
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    font-size: 11px;
    position: absolute;
    text-align: center;
    left: 3px;
    top: -1px;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.appie_checkbox_common input[type="checkbox"]:checked+label span {
    background: #2079ff;
    border-color: #2079ff;
}

.appie_checkbox_common input[type="checkbox"]:checked+label span:before {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.appie_checkbox_common input[type="checkbox"]+label span,
.appie_checkbox_common input[type="checkbox"]:checked+label span {
    -webkit-transition: background-color 0.2s linear;
    -o-transition: background-color 0.2s linear;
    -moz-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
}

.appie_checkbox_common.checkbox_style2 input[type="checkbox"]+label span {
    border-radius: 3px;
}

/*===========================
    11.APPIE ABOUT css 
===========================*/
.appie-about-box {
    background: #f0f1f7;
    padding: 70px;
    overflow: hidden;
    position: relative;
}

@media (max-width: 767px) {
    .appie-about-box {
        padding: 30px;
        text-align: center;
    }
}

.appie-about-box .about-thumb {
    position: absolute;
    left: 0;
    top: -65px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-about-box .about-thumb {
        position: static;
    }
}

@media (max-width: 767px) {
    .appie-about-box .about-thumb {
        display: none;
    }
}

.appie-about-content {
    padding-right: 50px;
}

@media (max-width: 767px) {
    .appie-about-content {
        padding-right: 0;
    }
}

.appie-about-content span {
    font-size: 14px;
    font-weight: 500;
    color: #ff3e66;
}

.appie-about-content .title {
    font-size: 44px;
    line-height: 54px;
    margin-top: 8px;
    margin-bottom: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-about-content .title {
        font-size: 32px;
        line-height: 42px;
    }
}

@media (max-width: 767px) {
    .appie-about-content .title {
        font-size: 30px;
        line-height: 40px;
    }
}

.appie-about-service {
    position: relative;
    padding-left: 40px;
}

@media (max-width: 767px) {
    .appie-about-service {
        text-align: left;
    }
}

.appie-about-service .icon {
    position: absolute;
    left: 0;
    top: 5px;
}

.appie-about-service .icon i {
    height: 26px;
    width: 26px;
    text-align: center;
    line-height: 26px;
    border-radius: 50%;
    color: #fff;
    background: #31c369;
}

.appie-about-service .title {
    font-size: 20px;
    font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-about-service .title {
        font-size: 16px;
    }
}

.appie-about-service p {
    font-size: 15px;
    margin-top: 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-about-service p {
        font-size: 14px;
    }
}

.appie-about-3-area .appie-traffic-title {
    padding-right: 100px;
}

@media (max-width: 767px) {
    .appie-about-3-area .appie-traffic-title {
        text-align: center;
        padding-right: 0;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-about-3-area .appie-traffic-title {
        text-align: left;
        padding-right: 50px;
    }
}

.appie-about-3-area .appie-traffic-title .title {
    margin-bottom: 15px;
}

.appie-about-3-area .appie-traffic-service {
    padding-left: 0;
    padding-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-about-3-area .appie-traffic-service {
        padding-right: 0;
    }
}

@media (max-width: 767px) {
    .appie-about-3-area .appie-traffic-service {
        text-align: center;
        padding-right: 30px;
        padding-left: 30px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-about-3-area .appie-traffic-service {
        text-align: left;
        padding-right: 50px;
        padding-left: 0;
    }
}

.appie-about-3-area .appie-traffic-service .icon {
    position: static;
    background-image: -moz-linear-gradient(120deg, #2b70fa 0%, #84bcfa 100%);
    background-image: -webkit-linear-gradient(120deg, #2b70fa 0%, #84bcfa 100%);
    background-image: -ms-linear-gradient(120deg, #2b70fa 0%, #84bcfa 100%);
    background-image: linear-gradient(120deg, #2b70fa 0%, #84bcfa 100%);
    display: inline-block;
    height: 50px;
    width: 50px;
    text-align: center;
    border-radius: 50%;
    line-height: 50px;
    box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
    margin-bottom: 23px;
}

.appie-about-3-area .appie-traffic-service.item-2 .icon {
    background-image: -moz-linear-gradient(120deg, #31c369 0%, #a0eac2 100%);
    background-image: -webkit-linear-gradient(120deg, #31c369 0%, #a0eac2 100%);
    background-image: -ms-linear-gradient(120deg, #31c369 0%, #a0eac2 100%);
    background-image: linear-gradient(120deg, #31c369 0%, #a0eac2 100%);
}

.appie-about-3-area .appie-traffic-service.item-3 .icon {
    background-image: -moz-linear-gradient(120deg, #9b2cfa 0%, #d8aaff 100%);
    background-image: -webkit-linear-gradient(120deg, #9b2cfa 0%, #d8aaff 100%);
    background-image: -ms-linear-gradient(120deg, #9b2cfa 0%, #d8aaff 100%);
    background-image: linear-gradient(120deg, #9b2cfa 0%, #d8aaff 100%);
}

.appie-about-3-area .appie-traffic-service.item-4 .icon {
    background-image: -moz-linear-gradient(120deg, #f8452d 0%, #f7b8af 100%);
    background-image: -webkit-linear-gradient(120deg, #f8452d 0%, #f7b8af 100%);
    background-image: -ms-linear-gradient(120deg, #f8452d 0%, #f7b8af 100%);
    background-image: linear-gradient(120deg, #f8452d 0%, #f7b8af 100%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-about-thumb-3 {
        margin-bottom: 40px;
    }
}

@media (max-width: 767px) {
    .appie-about-thumb-3 {
        margin-bottom: 40px;
    }
}

.appie-about-thumb-3 img {
    width: 100%;
}

.appie-about-top-title-area {
    padding-top: 227px;
}

.appie-about-top-title {
    padding-right: 65px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-about-top-title {
        padding-right: 0;
    }
}

@media (max-width: 767px) {
    .appie-about-top-title {
        padding-right: 0;
    }
}

.appie-about-top-title .title {
    font-size: 60px;
    position: relative;
    z-index: 10;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-about-top-title .title {
        font-size: 52px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-about-top-title .title {
        font-size: 44px;
    }
}

@media (max-width: 767px) {
    .appie-about-top-title .title {
        font-size: 32px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-about-top-title .title {
        font-size: 40px;
    }
}

.appie-about-top-title .title::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 8px;
    width: 100%;
    height: 20px;
    background: #ff6e85;
    z-index: -1;
}

.appie-about-top-title p {
    font-size: 20px;
    color: #0e1133;
    padding-top: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-about-top-title p {
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-about-top-title p {
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    .appie-about-top-title p {
        font-size: 16px;
    }
}

.appie-about-page-area {
    padding-top: 462px;
    padding-bottom: 100px;
    background-image: url(../images/about-page-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 345px;
}

@media (max-width: 767px) {
    .appie-about-page-area {
        margin-top: 80px;
        padding-top: 80px;
    }
}

.appie-about-page-content {
    position: relative;
}

.appie-about-page-content .title {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
}

.appie-about-page-content p {
    color: #fff;
    padding-top: 15px;
    opacity: 0.7;
}

.appie-about-page-content a {
    color: #fff;
    border: 2px solid #fff;
    border-radius: 10px;
    line-height: 40px;
    padding: 0 30px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 43px;
}

.appie-about-page-content a:hover {
    background: #fff;
    color: #2b70fa;
}

.appie-about-page-content::before {
    position: absolute;
    content: "";
    left: 0;
    top: -710px;
    height: 645px;
    width: 80vw;
    background-image: url(../images/about-page-thumb.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-about-page-content::before {
        width: 94vw;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-about-page-content::before {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .appie-about-page-content::before {
        display: none;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-about-page-content::before {
        width: 100%;
        height: 400px;
        display: block;
    }
}

.appie-how-it-work-content>.title {
    font-size: 44px;
}

.appie-how-it-work-content p {
    font-size: 18px;
    padding-top: 9px;
}

.appie-how-it-work-content .how-it-work-box {
    position: relative;
    padding-left: 70px;
    margin-top: 45px;
}

.appie-how-it-work-content .how-it-work-box span {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    background: #eef4ff;
    color: #2b70fa;
    font-size: 22px;
    font-weight: 500;
}

.appie-how-it-work-content .how-it-work-box .title {
    font-size: 18px;
    font-weight: 500;
}

.appie-how-it-work-content .how-it-work-box:hover span {
    background: #2b70fa;
    box-shadow: 0px 14px 20px 0px rgba(0, 43, 130, 0.3);
    color: #fff;
}

.appie-about-8-area {
    background-image: url(../images/background-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.appie-about-8-box {
    padding: 50px 50px 145px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
    position: relative;
    overflow: hidden;
    z-index: 10;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-about-8-box {
        padding: 50px 25px 145px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-about-8-box {
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .appie-about-8-box {
        margin-bottom: 30px;
        padding: 50px 20px 145px;
    }
}

.appie-about-8-box .thumb {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.appie-about-8-box .title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 14px;
}

@media (max-width: 767px) {
    .appie-about-8-box .title {
        font-size: 24px;
        line-height: 34px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-about-8-box .title {
        font-size: 30px;
        line-height: 40px;
    }
}

.appie-about-8-box a {
    border: 2px solid #db0f30;
    color: #db0f30;
    background: #fff;
    margin-top: 30px;
}

.appie-about-8-box a:hover {
    color: #fff;
    background: #db0f30;
    border-color: #db0f30;
}

/*===========================
    12.APPIE COUNTER css 
===========================*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-counter-area {
        padding-bottom: 100px;
    }
}

@media (max-width: 767px) {
    .appie-counter-area {
        padding-bottom: 100px;
    }
}

.appie-counter-area.appie-counter-5-area {
    background: #eef1f6;
}

.appie-single-counter {
    padding: 30px;
    position: relative;
    background: #fff;
    height: 160px;
    background-image: -moz-linear-gradient(140deg, #ddf4fd 0%, #eaf9ff 100%);
    background-image: -webkit-linear-gradient(140deg, #ddf4fd 0%, #eaf9ff 100%);
    background-image: -ms-linear-gradient(140deg, #ddf4fd 0%, #eaf9ff 100%);
    background-image: linear-gradient(140deg, #ddf4fd 0%, #eaf9ff 100%);
    border-radius: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-single-counter {
        padding: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-single-counter {
        margin-bottom: 90px;
    }
}

@media (max-width: 767px) {
    .appie-single-counter {
        margin-bottom: 90px;
    }
}

.appie-single-counter .counter-content {
    background: #fff;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0px 30px 40px 0px rgba(0, 41, 57, 0.1);
    padding-top: 37px;
    padding-bottom: 40px;
}

.appie-single-counter .counter-content .title {
    font-size: 40px;
    font-weight: 400;
    margin-top: 5px;
}

.appie-single-counter .counter-content .title span {
    font-weight: 700;
}

.appie-single-counter .counter-content p {
    font-size: 14px;
    font-weight: 500;
}

.appie-single-counter.item-2 {
    background-image: -moz-linear-gradient(140deg, #fdead3 0%, #fff7ee 100%);
    background-image: -webkit-linear-gradient(140deg, #fdead3 0%, #fff7ee 100%);
    background-image: -ms-linear-gradient(140deg, #fdead3 0%, #fff7ee 100%);
    background-image: linear-gradient(140deg, #fdead3 0%, #fff7ee 100%);
}

.appie-single-counter.item-2 .counter-content {
    box-shadow: 0px 30px 40px 0px rgba(56, 31, 0, 0.1);
}

.appie-single-counter.item-3 {
    background-image: -moz-linear-gradient(140deg, #dafedb 0%, #f0fcf0 100%);
    background-image: -webkit-linear-gradient(140deg, #dafedb 0%, #f0fcf0 100%);
    background-image: -ms-linear-gradient(140deg, #dafedb 0%, #f0fcf0 100%);
    background-image: linear-gradient(140deg, #dafedb 0%, #f0fcf0 100%);
}

.appie-single-counter.item-3 .counter-content {
    box-shadow: 0px 30px 40px 0px rgba(0, 74, 1, 0.1);
}

.appie-single-counter.item-4 {
    background-image: -moz-linear-gradient(140deg, #fedaf4 0%, #fceff7 100%);
    background-image: -webkit-linear-gradient(140deg, #fedaf4 0%, #fceff7 100%);
    background-image: -ms-linear-gradient(140deg, #fedaf4 0%, #fceff7 100%);
    background-image: linear-gradient(140deg, #fedaf4 0%, #fceff7 100%);
}

.appie-single-counter.item-4 .counter-content {
    box-shadow: 0px 30px 40px 0px rgba(74, 0, 54, 0.1);
}

/*===========================
    13.APPIE VIDEO PLAYER css 
===========================*/
.appie-video-player-slider .slick-arrow {
    position: absolute;
    right: 0;
    bottom: -90px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border: 2px solid #e3e5e8;
    color: #0e1133;
    border-radius: 50%;
    z-index: 9;
    cursor: pointer;
}

.appie-video-player-slider .slick-arrow:hover {
    background: #ff3e66;
    color: #fff;
    border-color: #ff3e66;
}

.appie-video-player-slider .slick-arrow.prev {
    right: 60px;
}

.appie-video-player-slider .slick-arrow.next {
    right: 0;
}

.appie-video-player-slider .item img {
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-video-player-item {
        margin-bottom: 50px;
    }
}

@media (max-width: 767px) {
    .appie-video-player-item {
        margin-bottom: 50px;
    }
}

.appie-video-player-item .thumb {
    position: relative;
    overflow: hidden;
}

.appie-video-player-item .thumb img {
    width: 100%;
}

.appie-video-player-item .thumb .video-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.appie-video-player-item .thumb .video-popup a {
    height: 90px;
    width: 90px;
    background: #fff;
    text-align: center;
    line-height: 90px;
    color: #ff3e66;
    border-radius: 50%;
    font-size: 20px;
    position: relative;
}

.appie-video-player-item .thumb .video-popup a::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 50%;
    animation: linear infinite popup 1.5s;
}

.appie-video-player-item .content {
    padding-left: 100px;
    padding-top: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-video-player-item .content {
        padding-left: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-video-player-item .content {
        padding-left: 0px;
    }
}

@media (max-width: 767px) {
    .appie-video-player-item .content {
        padding-left: 0px;
    }
}

.appie-video-player-item .content .title {
    font-size: 30px;
    margin-bottom: 12px;
}

@media (max-width: 767px) {
    .appie-video-player-item .content .title {
        font-size: 26px;
    }
}

.appie-video-player-item .content a {
    border-radius: 30px;
    border: 2px solid #e7eaef;
    background: #fff;
    color: #0e1133;
    margin-top: 33px;
}

.appie-video-player-item .content a:hover {
    background: #ff3e66;
    border-color: #fff;
    color: #fff;
}

@keyframes popup {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    25% {
        transform: scale(1.1);
        opacity: 0.8;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.6;
    }

    75% {
        transform: scale(1.3);
        opacity: 0.4;
    }

    100% {
        transform: scale(1.4);
        opacity: 0;
    }
}

.appie-video-player-6-area .appie-video-player-item .content a:hover {
    background: #9b2cfa;
    border-color: #9b2cfa;
}

.appie-video-player-6-area .appie-video-player-slider .slick-arrow:hover {
    background: #9b2cfa;
    border-color: #9b2cfa;
}

/*===========================
    14.APPIE DOWNLOAD css 
===========================*/
.appie-download-area {
    background-image: url(../images/download-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    z-index: 10;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-download-area {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.appie-download-area::before {
    position: absolute;
    content: "";
    right: 170px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    width: 860px;
    background-image: url(../images/download-thumb.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-download-area::before {
        right: 0;
        width: 550px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-download-area::before {
        display: none;
    }
}

@media (max-width: 767px) {
    .appie-download-area::before {
        display: none;
    }
}

.appie-download-area .download-shape-1 {
    position: absolute;
    left: 95px;
    top: 95px;
    z-index: -1;
    animation: linear 20s animationFramesFour infinite;
}

.appie-download-area .download-shape-2 {
    position: absolute;
    left: 150px;
    bottom: 100px;
    z-index: -1;
    animation: linear 20s animationFramesTwo infinite;
}

.appie-download-area .download-shape-3 {
    position: absolute;
    top: 140px;
    right: 250px;
    z-index: -1;
    animation: linear 20s animationFramesFive infinite;
}

.appie-download-content>span {
    font-size: 14px;
    font-weight: 500;
    color: #ff3e66;
}

.appie-download-content .title {
    font-size: 44px;
    line-height: 54px;
    margin-top: 9px;
    margin-bottom: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-download-content .title {
        font-size: 36px;
        line-height: 46px;
    }
}

@media (max-width: 767px) {
    .appie-download-content .title {
        font-size: 30px;
        line-height: 40px;
    }
}

.appie-download-content ul {
    margin: 30px 0 0;
    padding: 0;
    list-style-type: none;
}

.appie-download-content ul li {
    display: inline-block;
}

.appie-download-content ul li a {
    background: #0e1133;
    color: #fff;
    padding-left: 22px;
    padding-right: 40px;
    padding-top: 14px;
    padding-bottom: 14px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    margin-right: 8px;
    border: 2px solid #0e1133;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-download-content ul li a {
        padding-right: 20px;
    }
}

@media (max-width: 767px) {
    .appie-download-content ul li a {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-download-content ul li a {
        margin-bottom: 0px;
    }
}

.appie-download-content ul li a i {
    font-size: 36px;
    padding-right: 11px;
}

.appie-download-content ul li a span {
    font-size: 14px;
    font-weight: 500;
}

.appie-download-content ul li a span span {
    font-size: 24px;
    display: block;
    line-height: 18px;
}

.appie-download-content ul li a:hover {
    background: #fff;
    color: #0e1133;
}

.appie-download-content ul li a.item-2 {
    background: #fff;
    color: #0e1133;
}

.appie-download-content ul li a.item-2:hover {
    background: #0e1133;
    color: #fff;
}

.appie-sponser-area {
    position: relative;
    z-index: 10;
}

.appie-sponser-area .sponser-shape {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

@media (max-width: 767px) {
    .appie-sponser-box {
        flex-wrap: wrap;
    }
}

.appie-sponser-box .sponser-item {
    background: #fff;
    height: 100px;
    width: 100px;
    text-align: center;
    line-height: 100px;
    border-radius: 6px;
    box-shadow: 0px 40px 60px 0px rgba(14, 17, 51, 0.14);
    margin: 30px 16px 0;
}

.appie-sponser-box.item-2 .sponser-item {
    margin: 30px 28px 0;
}

@media (max-width: 767px) {
    .appie-sponser-box.item-2 .sponser-item {
        margin: 30px 16px 0;
    }
}

.appie-download-3-box {
    border-radius: 20px;
    background: #ebf2fc;
    overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-download-3-box {
        margin: 30px 0 0;
    }
}

@media (max-width: 767px) {
    .appie-download-3-box {
        margin: 30px 0 0;
    }
}

.appie-download-3-box .content {
    padding: 50px;
}

@media (max-width: 767px) {
    .appie-download-3-box .content {
        padding: 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-download-3-box .content {
        padding: 50px;
    }
}

.appie-download-3-box .content .title {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 8px;
}

.appie-download-3-box .content a.main-btn {
    background: #fff;
    color: #0e1133;
    border-color: #fff;
    margin-top: 33px;
}

.appie-download-3-box .content a.main-btn:hover {
    background: #ff6b58;
    border-color: #ff6b58;
    color: #fff;
}

.appie-download-3-box .content a.main-btn i {
    padding-right: 6px;
}

.appie-download-3-box .content a.main-btn.main-btn-2 {
    background: #ff6b58;
    border-color: #ff6b58;
    color: #fff;
}

.appie-download-3-box .content a.main-btn.main-btn-2:hover {
    background: #fff;
    color: #0e1133;
    border-color: #fff;
}

.appie-download-3-box.appie-download-6-box a.main-btn:hover {
    background: #9b2cfa;
    border-color: #9b2cfa;
}

.appie-download-3-box.appie-download-6-box a.main-btn.main-btn-2 {
    background: #9b2cfa;
    border-color: #9b2cfa;
}

.appie-download-3-box.appie-download-5-box a.main-btn:hover {
    background: #801f82;
    border-color: #801f82;
}

.appie-download-3-box.appie-download-5-box a.main-btn.main-btn-2 {
    background: #801f82;
    border-color: #801f82;
}

/*===========================
    15.APPIE FUN FACT css 
===========================*/
.appie-fun-fact-box {
    background-image: url(../images/fun-fact-bg.jpg);
    padding: 90px 70px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
}

@media (max-width: 767px) {
    .appie-fun-fact-box {
        padding: 30px;
    }
}

.appie-fun-fact-box .appie-fun-fact-content>.title {
    font-size: 44px;
    line-height: 54px;
    color: #fff;
    padding-right: 80px;
    padding-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .appie-fun-fact-box .appie-fun-fact-content>.title {
        font-size: 40px;
        line-height: 50px;
    }
}

@media (max-width: 767px) {
    .appie-fun-fact-box .appie-fun-fact-content>.title {
        font-size: 28px;
        line-height: 38px;
        padding-right: 0;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-fun-fact-box .appie-fun-fact-content>.title {
        font-size: 36px;
        line-height: 46px;
        padding-right: 0;
    }
}

.appie-fun-fact-box .appie-fun-fact-content p {
    color: #fff;
    opacity: 0.8;
}

.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item {
    margin-top: 36px;
}

@media (max-width: 767px) {
    .appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item {
        text-align: center;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item {
        text-align: left;
    }
}

.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item .title {
    font-size: 30px;
    color: #fff;
}

.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item span {
    font-size: 14px;
    color: #a4a4b9;
}

.appie-fun-fact-box .appie-fun-fact-play {
    position: relative;
    z-index: 10;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-fun-fact-box .appie-fun-fact-play {
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .appie-fun-fact-box .appie-fun-fact-play {
        margin-top: 50px;
        margin-bottom: 20px;
        text-align: center;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .appie-fun-fact-box .appie-fun-fact-play {
        text-align: left;
    }
}

.appie-fun-fact-box .appie-fun-fact-play img {
    position: absolute;
    right: -40px;
    top: 0;
    z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-fun-fact-box .appie-fun-fact-play img {
        position: static;
    }
}

@media (max-width: 767px) {
    .appie-fun-fact-box .appie-fun-fact-play img {
        display: none;
    }
}

.appie-fun-fact-box .appie-fun-fact-play a {
    height: 70px;
    width: 70px;
    text-align: center;
    line-height: 70px;
    background: #fff;
    border-radius: 50%;
    color: #ff6b58;
    position: relative;
    z-index: 5;
}

.appie-fun-fact-box .appie-fun-fact-play a::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 50%;
    animation: popup 1.5s linear infinite;
}

/*===========================
    15.APPIE SHOWCASE css 
===========================*/
.appie-showcase-area {
    background: #eef1f6;
    padding-top: 90px;
    padding-bottom: 120px;
    position: relative;
    overflow: hidden;
    z-index: 10;
}

.appie-showcase-area .showcase-shape-1 {
    position: absolute;
    left: 90px;
    top: 185px;
    z-index: -1;
    animation: linear 25s animationFramesFive infinite;
}

.appie-showcase-area .showcase-shape-2 {
    position: absolute;
    left: 170px;
    bottom: 95px;
    z-index: -1;
    animation: linear 20s animationFramesOne infinite;
}

.appie-showcase-area .showcase-shape-3 {
    position: absolute;
    top: 200px;
    right: 0;
    z-index: -1;
    animation: linear 25s animationFramesThree infinite;
}

.appie-showcase-area .showcase-shape-4 {
    position: absolute;
    bottom: 250px;
    right: 195px;
    z-index: -1;
    animation: linear 25s animationFramesFour infinite;
}

.appie-showcase-item {
    box-shadow: 0px 2px 3px 0px rgba(14, 17, 51, 0.1);
    border-radius: 10px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    margin-top: 30px;
    margin-bottom: 50px;
}

.appie-showcase-item:hover {
    box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
    transform: translateY(-10px);
}

.appie-showcase-item a {
    display: block;
}

.appie-showcase-item img {
    width: 100%;
}

.appie-showcase-slider .slick-dots {
    position: absolute;
    left: 50%;
    bottom: -20px;
    transform: translateX(-50%);
}

.appie-showcase-slider .slick-dots li {
    display: inline-block;
}

.appie-showcase-slider .slick-dots li button {
    font-size: 0;
    border: 0;
    background: #ccced9;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin: 0 3px;
}

.appie-showcase-slider .slick-dots li.slick-active button {
    background: #ff6b58;
}

/*===========================
    16.APPIE CONTACT css 
===========================*/
.contact-section {
    position: relative;
    background: #f7f6fa;
    padding: 120px 0;
}

.contact--info-area {
    position: relative;
    margin-right: 40px;
}

.contact--info-area h3 {
    font-size: 30px;
    color: #2c234d;
    font-weight: 800;
    line-height: 0.8;
    margin-bottom: 16px;
}

.contact--info-area>p {
    color: #686875;
    line-height: 24px;
    border-bottom: 1px solid #e5e4ed;
    margin-bottom: 33px;
    padding-bottom: 25px;
}

.single-info {
    position: relative;
    margin-bottom: 37px;
}

.single-info h5 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #2c234d;
}

.single-info p {
    position: relative;
    font-size: 14px;
    line-height: 22px;
    color: #686875;
    margin: 0;
    padding-left: 32px;
}

.single-info p i {
    font-size: 16px;
    line-height: 22px;
    color: #2b70fa;
    position: absolute;
    left: 0;
    top: 0;
}

.ab-social h5 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 24px;
    color: #2c234d;
}

.bisylms-map {
    position: relative;
    line-height: 0.8;
    overflow: hidden;
}

.bisylms-map iframe {
    width: 100%;
    height: 480px;
    border: none;
}

.contact-form {
    position: relative;
    background: #fff;
    border-radius: 10px;
    padding: 43px 50px 65px;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(11, 2, 55, 0.06);
    -moz-box-shadow: 0px 1px 3px 0px rgba(11, 2, 55, 0.06);
    box-shadow: 0px 1px 3px 0px rgba(11, 2, 55, 0.06);
}

.contact-form h4 {
    font-size: 24px;
    color: #2c234d;
    line-height: 30px;
    margin-bottom: 8px;
}

.contact-form p {
    font-size: 16px;
    color: #686875;
    line-height: 26px;
    position: relative;
    z-index: 3;
    margin: 0 0 41px;
}

.contact-form input[type="text"],
.contact-form input[type="number"],
.contact-form textarea,
.contact-form input[type="email"] {
    width: 100%;
    height: 56px;
    font-size: 14px;
    line-height: 56px;
    font-weight: 400;
    border-radius: 3px;
    border: none;
    background: #f5f5f7;
    color: #7e7c87;
    outline: none;
    border: 1px solid transparent;
    padding: 0 30px;
    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.contact-form textarea {
    height: 220px;
    resize: none;
    line-height: 30px;
    padding-top: 14px;
}

.contact-form textarea::-moz-placeholder,
.contact-form input[type="text"]::-moz-placeholder,
.contact-form input[type="number"]::-moz-placeholder,
.contact-form input[type="email"]::-moz-placeholder {
    color: #7e7c87;
    opacity: 1;
}

.contact-form textarea::-ms-input-placeholder,
.contact-form input[type="text"]::-ms-input-placeholder,
.contact-form input[type="number"]::-ms-input-placeholder,
.contact-form input[type="email"]::-ms-input-placeholder {
    color: #7e7c87;
    opacity: 1;
}

.contact-form textarea::-webkit-input-placeholder,
.contact-form input[type="text"]::-webkit-input-placeholder,
.contact-form input[type="number"]::-webkit-input-placeholder,
.contact-form input[type="email"]::-webkit-input-placeholder {
    color: #7e7c87;
    opacity: 1;
}

.contact-form textarea:focus,
.contact-form input[type="text"]:focus,
.contact-form input[type="number"]:focus,
.contact-form input[type="email"]:focus {
    background: transparent;
    border-color: #ece9f9;
    -webkit-box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
    -moz-box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
    box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
}

.contact-form input[type="submit"] {
    height: 50px;
    border-radius: 3px;
    background: #00d1b3;
    color: #fff;
    text-align: center;
    border: none;
    outline: none;
    padding: 0 36px;
    font-size: 13px;
    line-height: 50px;
    font-weight: 600;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.contact-form input[type="submit"]:hover {
    -webkit-box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
    -moz-box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
    box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
}

.condition-check {
    position: relative;
    margin: 10px 0 0;
}

.condition-check input[type="checkbox"] {
    display: none;
}

.condition-check label {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
    font-weight: 400;
    padding-left: 31px;
    position: relative;
    cursor: pointer;
    color: #686875;
    margin: 0 0 0;
}

.condition-check label a {
    color: #2b70fa;
}

.condition-check label a:hover {
    color: #686875;
}

.condition-check label:before {
    background: transparent;
    content: "";
    height: 16px;
    left: 0;
    position: absolute;
    border-radius: 3px;
    top: 3px;
    width: 16px;
    border: 2px solid #ababb1;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.condition-check input:checked+label::after {
    background: transparent;
    bottom: 0;
    font-family: "Font Awesome 5 Pro";
    content: "\f00c";
    color: #2b70fa;
    font-size: 10px;
    left: 3px;
    position: absolute;
    top: -1px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.ab-social {
    position: relative;
}

.ab-social a {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #446bb9;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    font-size: 14px;
    margin-right: 2px;
}

.ab-social a.fac {
    background: #00d1b3;
}

.ab-social a.twi {
    background: #00aced;
}

.ab-social a.you {
    background: #1c435d;
}

.ab-social a.lin {
    background: #00ceff;
}

.ab-social a:hover {
    -webkit-box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
    -moz-box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
    box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
}

/*===========================
    17.APPIE CONTACT css 
===========================*/
.appie-shop-grid-area .bisylms-pagination {
    margin-top: 50px;
    margin-bottom: 50px;
}

.appie-shop-grid-area .bisylms-pagination .current,
.appie-shop-grid-area .bisylms-pagination:hover {
    box-shadow: none;
    border-radius: 5px;
}

.appie-shop-grid-area .bisylms-pagination a {
    border: 2px solid #e7eaef;
    background: #fff;
    color: #0e1133;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
}

.appie-shop-grid-area .bisylms-pagination a i {
    font-size: 14px;
    padding-left: 6px;
}

.appie-shop-grid-area .bisylms-pagination a:hover {
    box-shadow: none;
    background: #2b70fa;
    border-color: #2b70fa;
    color: #fff;
}

.appie-shop-grid-area .bisylms-pagination span {
    border: 2px solid #e7eaef;
}

.appie-shop-sidebar .shop-price-filter {
    border-bottom: 1px solid #e7eaef;
    padding-bottom: 33px;
}

.appie-shop-sidebar .shop-price-filter .title {
    font-size: 16px;
    font-weight: 700;
}

.appie-shop-sidebar .shop-price-filter .price-range {
    width: 100%;
    height: 3px;
    background: #e7eaef;
    margin-top: 33px;
    margin-bottom: 24px;
    position: relative;
}

.appie-shop-sidebar .shop-price-filter .price-range::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 0;
    height: 100%;
    width: 60%;
    background: #2b70fa;
    transform: translateX(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-shop-sidebar .shop-price-filter .price-range::before {
        width: 100%;
    }
}

.appie-shop-sidebar .shop-price-filter .price-range .box-1 {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);
    background: #2b70fa;
    box-shadow: 0px 4px 10px 0px rgba(0, 14, 58, 0.2);
    cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-shop-sidebar .shop-price-filter .price-range .box-1 {
        left: 0;
    }
}

.appie-shop-sidebar .shop-price-filter .price-range .box-2 {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    background: #2b70fa;
    box-shadow: 0px 4px 10px 0px rgba(0, 14, 58, 0.2);
    cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appie-shop-sidebar .shop-price-filter .price-range .box-2 {
        right: 0;
    }
}

.appie-shop-sidebar .shop-price-filter .main-filter-price span {
    font-size: 14px;
    color: #63636a;
}

.appie-shop-sidebar .shop-price-filter .main-filter-price a {
    font-size: 14px;
    color: #0e1133;
}

.appie-shop-sidebar .shop-category-widget {
    margin-top: 37px;
    border-bottom: 1px solid #e7eaef;
    padding-bottom: 33px;
}

.appie-shop-sidebar .shop-category-widget .title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 25px;
}

.appie-shop-sidebar .shop-category-widget ul li {
    margin-top: 10px;
}

.appie-shop-sidebar .shop-category-widget ul li a {
    font-size: 15px;
    color: #63636a;
}

.appie-shop-sidebar .shop-category-widget ul li a:hover {
    color: #2b70fa;
}

.appie-shop-sidebar .shop-best-product-widget {
    margin-top: 37px;
    border-bottom: 1px solid #e7eaef;
    padding-bottom: 40px;
}

.appie-shop-sidebar .shop-best-product-widget .title {
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
}

.appie-shop-sidebar .shop-best-product-widget .shop-product {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.appie-shop-sidebar .shop-best-product-widget .shop-product .thumb {
    max-width: 60px;
    margin-right: 20px;
}

.appie-shop-sidebar .shop-best-product-widget .shop-product .content a {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #2b70fa;
    color: #0e1133;
}

.appie-shop-sidebar .shop-best-product-widget .shop-product .content span {
    font-size: 14px;
    font-weight: 400;
    color: #63636a;
}

.appie-shop-sidebar .shop-tags-bar {
    margin-top: 37px;
}

.appie-shop-sidebar .shop-tags-bar .title {
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
}

.appie-shop-sidebar .shop-tags-bar ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.appie-shop-sidebar .shop-tags-bar ul li {
    display: inline-block;
}

.appie-shop-sidebar .shop-tags-bar ul li a {
    border: 2px solid #e7eaef;
    line-height: 30px;
    padding: 0 13px;
    font-size: 13px;
    font-weight: 500;
    color: #0e1133;
    margin-bottom: 10px;
    margin-right: 7px;
    border-radius: 3px;
}

.shop-grid-topbar {
    padding-bottom: 10px;
}

@media (max-width: 767px) {
    .shop-grid-topbar {
        display: block !important;
    }
}

.shop-grid-topbar>span {
    color: #63636a;
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .shop-grid-topbar>span {
        margin-bottom: 15px;
        display: block;
    }
}

.shop-grid-topbar>span span {
    color: #0e1133;
}

.shop-grid-topbar select {
    height: 30px;
    width: 170px;
    background: #eef1f6;
    border: 0;
    border-radius: 4px;
}

.single-shop-box {
    border: 1px solid #e7eaef;
    border-radius: 6px;
    padding: 20px;
    margin-top: 30px;
}

.single-shop-box .thumb {
    position: relative;
}

.single-shop-box .thumb img {
    transition: all linear 0.5s;
}

.single-shop-box .thumb .reborn {
    position: absolute;
    left: 0;
    top: 0;
}

.single-shop-box .thumb .reborn span {
    background: #2b70fa;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    line-height: 18px;
    padding: 0 12px;
    border-radius: 4px;
}

.single-shop-box .thumb .cart-list-icon {
    position: absolute;
    top: -20px;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: all linear 0.5s;
}

.single-shop-box .thumb .cart-list-icon ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.single-shop-box .thumb .cart-list-icon ul li a {
    height: 36px;
    width: 36px;
    text-align: center;
    line-height: 36px;
    background: #eef1f6;
    color: #0e1133;
    margin-bottom: 6px;
    border-radius: 5px;
}

.single-shop-box .thumb .cart-list-icon ul li a:hover {
    background: #2b70fa;
    color: #fff;
}

.single-shop-box .content {
    padding-bottom: 10px;
}

.single-shop-box .content ul li {
    display: inline-block;
}

.single-shop-box .content ul li i {
    color: #ff9a2d;
    font-size: 12px;
}

.single-shop-box .content a {
    font-size: 14px;
    font-weight: 500;
    color: #0e1133;
    margin-bottom: 5px;
}

.single-shop-box .content .pricing {
    display: flex;
    font-size: 18px;
    font-weight: 700;
    color: #0e1133;
}

.single-shop-box .content .pricing .regular-price {
    color: #63636a;
    font-weight: 400;
    text-decoration: line-through;
    margin-left: 10px;
}

.single-shop-box:hover .thumb img {
    transform: scale(1.1);
}

.single-shop-box:hover .thumb .cart-list-icon {
    top: 0;
    opacity: 1;
    visibility: visible;
}

.shop-details-thumb .shop-details-thumb-slider-active .item {
    border-radius: 6px;
    overflow: hidden;
}

.shop-details-thumb .shop-details-thumb-slider-active .item img {
    width: 100%;
}

.shop-small-slider-active {
    max-width: 310px;
}

.shop-small-slider-active .item {
    border-radius: 5px;
    margin-right: 10px;
}

.shop-buttons .product-quantity {
    border: 2px solid #e7eaef;
    display: inline-block;
    border-radius: 6px;
}

.shop-buttons .product-quantity button {
    width: 40px;
    height: 45px;
    padding: 0;
    background-color: #fff;
    font-size: 24px;
    color: #838b97;
    border: 0;
    border-radius: 6px;
}

.shop-buttons .product-quantity button:hover,
.shop-buttons .product-quantity button:focus {
    color: #2b70fa;
}

@media (max-width: 767px) {
    .shop-buttons .product-quantity button {
        width: 40px;
        height: 40px;
    }
}

.shop-buttons .product-quantity input {
    width: 40px;
    height: 45px;
    border: 0;
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin: 0 0;
    color: #0e1133;
}

@media (max-width: 767px) {
    .shop-buttons .product-quantity input {
        height: 40px;
    }
}

@media (max-width: 767px) {
    .shop-buttons a {
        margin-left: 0;
        margin-top: 15px;
        padding: 0 40px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .shop-product-details-content {
        padding-left: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .shop-product-details-content {
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .shop-product-details-content {
        padding-left: 0;
    }
}

.shop-product-details-content>span {
    background: #eef1f6;
    line-height: 26px;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 500;
    color: #0e1133;
    border-radius: 6px;
}

.shop-product-details-content .title {
    font-size: 40px;
    font-weight: 700;
    margin-top: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .shop-product-details-content .title {
        font-size: 32px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .shop-product-details-content .title {
        font-size: 32px;
    }
}

@media (max-width: 767px) {
    .shop-product-details-content .title {
        font-size: 28px;
    }
}

.shop-product-details-content .pricing {
    font-size: 20px;
    font-weight: 500;
    color: #0e1133;
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.shop-product-details-content .pricing .regular-price {
    font-size: 16px;
    color: #63636a;
    font-weight: 400;
    text-decoration: line-through;
}

.shop-product-details-content .review {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 17px;
}

.shop-product-details-content .review ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.shop-product-details-content .review ul li {
    display: inline-block;
}

.shop-product-details-content .review ul li i {
    color: #ff9a2d;
    font-size: 14px;
}

.shop-product-details-content .review span {
    font-size: 15px;
    color: #63636a;
    margin-left: 5px;
}

.shop-product-details-content p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
}

.shop-product-details-content .details-info {
    border-top: 1px solid #e7eaef;
    margin-top: 35px;
    padding-top: 25px;
}

.shop-product-details-content .details-info ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.shop-product-details-content .details-info ul li {
    font-size: 15px;
    color: #63636a;
    padding-bottom: 3px;
}

.shop-product-details-content .details-info ul li span {
    font-weight: 500;
    color: #0e1133;
}

.shop-details-info-area {
    background: #eef1f6;
}

.shop-details-box .nav {
    border-bottom: 1px solid #d8dce3;
}

.shop-details-box .nav .nav-item .nav-link {
    margin: 0;
    padding: 0 10px;
    line-height: 45px;
    margin-right: 10px;
    color: #0e1133;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0;
}

.shop-details-box .nav .nav-item .nav-link.active {
    background: #eef1f6;
    color: #2b70fa;
    border-bottom: 2px solid #2b70fa;
}

.shop-description p {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 24px;
}

.shop-description .title {
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
}

.review-rating-box .top-rating-result .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
}

.review-rating-box .top-rating-result .rating-result-box {
    background: #fff;
    padding: 30px;
    margin-top: 16px;
    display: flex;
    border-radius: 6px;
}

@media (max-width: 767px) {
    .review-rating-box .top-rating-result .rating-result-box {
        display: block;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .review-rating-box .top-rating-result .rating-result-box {
        display: flex;
    }
}

.review-rating-box .top-rating-result .rating-result-box .thumb {
    max-width: 50px;
    margin-right: 20px;
}

@media (max-width: 767px) {
    .review-rating-box .top-rating-result .rating-result-box .thumb {
        margin-bottom: 15px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .review-rating-box .top-rating-result .rating-result-box .thumb {
        margin-bottom: 0px;
    }
}

.review-rating-box .top-rating-result .rating-result-box .content ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.review-rating-box .top-rating-result .rating-result-box .content ul li {
    display: inline-block;
}

.review-rating-box .top-rating-result .rating-result-box .content ul li i {
    font-size: 14px;
    color: #ff9a2d;
}

.review-rating-box .top-rating-result .rating-result-box .content .shop-meta {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
}

@media (max-width: 767px) {
    .review-rating-box .top-rating-result .rating-result-box .content .shop-meta {
        display: block;
    }
}

.review-rating-box .top-rating-result .rating-result-box .content .shop-meta .author-user-name a {
    font-size: 14px;
    font-weight: 700;
    color: #0e1133;
    margin-right: 15px;
}

.review-rating-box .top-rating-result .rating-result-box .content .shop-meta .date {
    font-size: 14px;
    color: #63636a;
}

.review-rating-box .top-rating-result .rating-result-box .content p {
    font-size: 14px;
    line-height: 24px;
}

.review-box .review-title {
    margin-top: 75px;
    margin-bottom: 20px;
}

.review-box .review-title .title {
    font-size: 20px;
    font-weight: 700;
}

.review-box .review-title p {
    font-size: 14px;
    padding-top: 3px;
}

.review-box .add-review-star span {
    color: #0e1133;
    font-weight: 500;
    padding-bottom: 4px;
}

.review-box .add-review-star ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.review-box .add-review-star ul li {
    display: inline-block;
}

.review-box .add-review-star ul li i {
    color: #9c9ea4;
}

.review-box .add-review-star ul li:nth-child(1) i,
.review-box .add-review-star ul li:nth-child(2) i {
    color: #ff9a2d;
}

.rating-form-box .input-box {
    margin-top: 15px;
}

.rating-form-box .input-box label {
    font-size: 14px;
    font-weight: 500;
    color: #0e1133;
}

.rating-form-box .input-box>input {
    width: 100%;
    height: 60px;
    padding-left: 25px;
    border: 2px solid #fff;
    background: #fff;
    border-radius: 6px;
}

.rating-form-box .input-box>input:focus {
    border-color: #2b70fa;
}

.rating-form-box .input-box textarea {
    width: 100%;
    height: 120px;
    padding-left: 25px;
    border: 2px solid #fff;
    background: #fff;
    border-radius: 6px;
    resize: none;
    padding-top: 10px;
}

.rating-form-box .input-box textarea:focus {
    border-color: #2b70fa;
}

.rating-form-box .input-box .condition-check label {
    font-size: 14px;
    color: #63636a;
    font-weight: 400;
}

.shop-related-product-slider-active .slick-dots {
    position: absolute;
    left: 50%;
    bottom: -70px;
    transform: translateX(-50%);
}

.shop-related-product-slider-active .slick-dots li {
    display: inline-block;
}

.shop-related-product-slider-active .slick-dots li button {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin: 0 5px;
    font-size: 0;
    border: 0;
}

.shop-related-product-slider-active .slick-dots li.slick-active button {
    background: #2b70fa;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 8px;
    border: 3px solid #0ed1b3;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0ed1b3 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

/*# sourceMappingURL=style.css.map */